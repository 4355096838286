// 多盖印人 显示
<template>
  <SealerPopover :list="list.map(it => ({ ...it, name: it.staffName }))">
    <section class="staff-box-wrapper" ref="wrapper">
      <div v-for="item in newList" :key="item.staffid" class="staff-box">
        {{ item.staffName }}
      </div>
      <div class="more" v-if="moreFlag">…</div>
    </section>
  </SealerPopover>
</template>

<script setup>
import SealerPopover from '../SealerPopover/index.vue'
import { defineProps, onBeforeMount, ref } from 'vue'
const { list } = defineProps({
  list: {
    type: Array,
    default: () => []
  }
})
console.log(list)
const wrapper = ref()
const newList = ref() // 处理后的数据
const moreFlag = ref() // 是否显示跟多的
const getFrontWidth = font => {
  const reg = /^[\u4E00-\u9FA5]+$/
  const flag = reg.test(font) ? 14 : 8.14
  return flag
}
/*  
获取整个tag 的宽度
14 padding+border+margin
*/
const getTagWidth = str => {
  var fontWidth = 0
  const reg = /^[\u4E00-\u9FA5]+$/
  for (var i = 0; i < str.length; i++) {
    fontWidth += getFrontWidth(str[i])
  }
  fontWidth = Math.ceil(fontWidth) >= 102 ? 102 : Math.ceil(fontWidth)
  return fontWidth + 14
}
//
const setShowSealer = () => {
  var staffBoxWidth = 0
  const maxW = 260 * 2
  const moreW = 22 //更多按钮
  for (var i = 0; i < list.length; i++) {
    // 第一行放不下当前元素换行的时候 要从260开始加
    if (staffBoxWidth <= 260 && staffBoxWidth + getTagWidth(list[i].staffName) > 260) {
      console.log(list[i].staffName)
      staffBoxWidth = 260
    }
    staffBoxWidth += getTagWidth(list[i].staffName)
    console.log(staffBoxWidth, 'max:', maxW)
    console.log('11111', i, staffBoxWidth, list[i].staffName, staffBoxWidth + moreW)

    if (staffBoxWidth + moreW > maxW) {
      //   加上更多 放不下
      console.log(list[i].staffName, i, 'qqqq')
      moreFlag.value = true
      // i--
      break
    } else {
      moreFlag.value = false
    }
  }
  newList.value = list.slice(0, i)
}
onBeforeMount(() => {
  setShowSealer()
})
</script>

<style lang="less" scoped>
.staff-box-wrapper {
  max-width: 260px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  max-height: 52px;
  overflow: hidden;
}
.staff-box {
  padding: 0 4px;
  background: #fbfbfc;
  border-radius: 2px;
  border: 1px solid #dcdee0;
  max-width: 8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;
  margin-bottom: 4px;
  font-family: Monospace;
  font-weight: 400;
}
.more {
  width: 22px;
  height: 24px;
  line-height: 24px;
  background: #fbfbfc;
  border-radius: 2px;
  border: 1px solid #dcdee0;
  color: #969799;
  text-align: center;
}
// 8.41  14
</style>
