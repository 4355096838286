<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-05-05 16:30:00
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-01-17 15:56:37
 * @FilePath: \yda_web_manage\src\views\approval\approved\index.vue
 * @Description: 用印审批3.0
 *
-->

<template>
  <section class="seal-tabs">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="2" tab="待审批" force-render v-btn="'PC_APPROVED_TODO'">
        <not-approval-table />
      </a-tab-pane>
      <a-tab-pane key="1" tab="已审批" v-btn="'PC_APPROVED_DONE'" force-render>
        <approved-table />
      </a-tab-pane>
    </a-tabs>
  </section>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import notApprovalTable from '../components/notApprovalTable'
import approvedTable from '../components/approvedTable'
import { permissionCheck } from '@/apis/sealManage.js'

export default defineComponent({
  components: {
    notApprovalTable,
    approvedTable
  },
  setup() {
    const activeKey = ref('2')
    //判断用户是否有待审批的权限
    const getPermissionCheck = () => {
      permissionCheck({
        code: 'PC_APPROVED_TODO'
      })
        .then(res => {
          activeKey.value = res.data ? '2' : '1'
        })
        .catch(err => {})
    }
    onMounted(() => {
      getPermissionCheck()
    })
    return {
      activeKey
    }
  }
})
</script>

<style lang="scss" scoped>
.seal-tabs {
  margin-top: -36px;
  :deep(.ant-tabs-nav-container) {
    background-color: #ffffff;
    padding: 0 24px;
  }
  :deep(.ant-tabs-nav .ant-tabs-tab) {
    padding: 13px 0;
    font-size: 14px;
  }
  :deep(.ant-tabs-bar) {
    margin: 0;
  }
  :deep(.ant-tabs-tabpane-active) {
    padding: 24px 24px 0;
  }
}
</style>
