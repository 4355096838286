<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-17 11:10:50
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2024-01-17 16:22:52
 * @FilePath: \yda_web_manage\src\views\seal\applyList\index.vue
 * @Description: 用印记录
-->
<template>
  <section class="seal-tabs">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="2" tab="普通用印" force-render v-btn="'PC_SEAL_LOG_COMMON'">
        <base-apply-list :reload="baseLoad" :userId="userId" :status="sealStatus" />
      </a-tab-pane>
      <a-tab-pane key="1" tab="特权人用印" force-render v-btn="'PC_SEAL_LOG_PRIVILEGE'">
        <fingerprint-list :reload="fingerprintLoad" :status="sealStatus" :userId="userId" @file-modal="fileModal"
          @file-modalview="fileviewModal" />
      </a-tab-pane>
    </a-tabs>
  </section>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import BaseApplyList from './components/baseApplyList'
import FingerprintList from './components/fingerprintList'
import { getFingerprintDetail } from '@/apis/seal'
import { useRoute } from 'vue-router'
import { permissionCheck } from '@/apis/sealManage.js'

export default defineComponent({
  name: 'applyList',
  components: {
    BaseApplyList,
    FingerprintList,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const userId = computed(() => store.state.user.userInfo.userId)
    const state = reactive({
      activeKey: route.query.activeKey ? route.query.activeKey : '2',
      baseApplyList: [],
      documentId: undefined,
      recordinfo: undefined,
      baseLoad:false,
      sealStatus: route.query.sealStatus ? ~~route.query.sealStatus : undefined
    })
    //判断用户是否有待审批的权限
    const getPermissionCheck = () => {
      permissionCheck({
        code: 'PC_SEAL_LOG_COMMON'
      })
        .then(res => {
          state.activeKey = res.data ? '2' : '1'
        })
        .catch(err => { })
    }

    onMounted(() => {
      getPermissionCheck()
      if (route.query.activeKey == 1) {
        fileModal(route.query.documentId, 'send')
        // fileviewModal(route.query.documentId, 'send')
      }
    })

    return {
      ...toRefs(state),
      userId
    }
  }
})
</script>

<style lang="scss" scoped>
.seal-tabs {
  margin-top: -36px;

  :deep(.ant-tabs-nav-container) {
    background-color: #ffffff;
    padding: 0 24px;
  }

  :deep(.ant-tabs-nav .ant-tabs-tab) {
    padding: 13px 0;
    font-size: 14px;
  }

  :deep(.ant-tabs-bar) {
    margin: 0;
  }

  :deep(.ant-tabs-tabpane-active) {
    padding: 24px 24px 0;
  }
}
</style>
