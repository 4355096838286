<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-06-05 14:06:38
 * @LastEditors: chenxu chenxu@libawall.cn
 * @LastEditTime: 2023-12-08 13:35:45
 * @FilePath: \yda_web_manage\src\views\businessManage\staff\index.vue
 * @Description: 
 * 
-->
<template>
  <section class="seal-tabs">
    <a-tabs v-model:activeKey="activeKey" @change="changTab">
      <a-tab-pane key="2" tab="成员" force-render>
        <Member ref="member" />
      </a-tab-pane>
      <a-tab-pane key="1" tab="组织">
        <Organization ref="organization" />
      </a-tab-pane>
    </a-tabs>
  </section>
</template>

<script>
import { reactive, toRefs, onMounted, ref, createVNode, onBeforeUnmount } from 'vue'
import Member from './components/member.vue'
import Organization from './components/tissue.vue'

export default {
  components: {
    Member,
    Organization
  },
  setup() {
    const member = ref(null)
    const organization = ref(null)
    const changTab = activeKey => {
      member.value.clearSelected()
      if (activeKey === '2') {
        member.value && member.value.getDepartment()
      } else {
        organization.value && organization.value.getDepartment()
      }
    }
    return {
      changTab,
      organization,
      member,
      activeKey: ref('2')
    }
  }
}
</script>

<style lang="scss" scoped>
.seal-tabs {
  margin-top: -36px;
  :deep(.ant-tabs-nav-container) {
    background-color: #ffffff;
    padding: 0 24px;
  }
  :deep(.ant-tabs-nav .ant-tabs-tab) {
    padding: 13px 0;
    font-size: 14px;
  }
  :deep(.ant-tabs-bar) {
    margin: 0;
  }
  :deep(.ant-tabs-tabpane-active) {
    padding: 24px 24px 0;
  }
}
</style>
