/*
 参数
 project：项目名称
 version：  版本号   每次开发新版本都需要维护修改
 level      DEBUG, INFO, WARN, ERROR
*/
const userInfo = localStorage.getItem('yda-admin-userInfo') && JSON.parse(localStorage.getItem('yda-admin-userInfo'))
const project = '智慧印章管理后台-' + process.env.NODE_ENV
const version = '2.5.10'
const level = 'DEBUG'
export const params = {
  userId: userInfo?.userId,
  sessionId: userInfo?.sessionId || localStorage.getItem('yda-qywx-sessionId'),
  project,
  version,
  level
}
