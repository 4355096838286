<template>
    <div class="custom-form-item">
        <div class="title">事项编号</div>
        <div class="content">{{ $attrs.value.taskCode }}</div>
    </div>
</template>

<script setup>
    import { useAttrs, onMounted } from 'vue'

    const attrs = useAttrs()

    onMounted(() => {
    console.log('attr的值111111', attrs)
    })
</script>

<style scoped>
    .title{
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        line-height: 20px;
        margin-bottom: 8px;
    }
</style>