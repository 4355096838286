<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-20 14:21:12
 * @LastEditors: 'Lizz' lzz1961690348@163.com
 * @LastEditTime: 2023-06-26 10:04:59
 * @FilePath: \yda_web_manage\src\components\Header\detailHeader.vue
 * @Description:详情页头部固定信息
 * 
-->
<template>
  <div class="detail-header">
    <div :class="['left', hasCover ? 'column' : 'row', showSealStatus && !hasCover ? 'big-padding' : 'small-padding']">
      <div :class="['line', hasCover ? 'margin-bottom' : '']">
        <!-- 流程主题 -->
        <div :class="['text-block', hasCover ? 'maximum-width' : '']" v-if="hasCover">
          <span class="title">流程主题：</span>
          <a-tooltip
            :title="headerInfo.fileName"
            overlayClassName="tooltip-custom"
            placement="bottom"
            color="white"
            v-if="headerInfo.fileName?.length > 12"
          >
            <div class="desc business-process">{{ headerInfo.fileName }}</div>
          </a-tooltip>
          <div class="desc business-process" v-else>
            {{ headerInfo.fileName ?? '-' }}
          </div>
        </div>

        <!-- 申请编码 -->
        <div :class="['text-block', hasCover ? 'maximum-width' : '']">
          <span class="title">申请编码：</span>
          <span class="desc">{{ headerInfo.applicationCode ?? '-' }}</span>
        </div>
        <!-- 流程类型-->
        <div :class="['text-block']" v-if="isAppoval">
          <span class="title">流程类型：</span>
          <span class="desc">{{ getEnums('PROCESS_MOLD', headerInfo.processType)?.desc ?? '-' }}</span>
        </div>
        <!-- 业务类型-->
        <div :class="['text-block']" v-if="getEnums('PROCESS_MOLD', headerInfo.processType)?.desc === '用印流程'">
          <span class="title">业务类型：</span>
          <span class="desc">{{ getEnums('PROCESS_TYPE', headerInfo.processType)?.desc ?? '-' }}</span>
        </div>
        <!-- 流程名称 -->
        <div :class="['text-block', hasCover ? 'maximum-width' : '']">
          <span class="title">流程名称：</span>
          <a-tooltip
            :title="headerInfo.processName"
            overlayClassName="tooltip-custom"
            placement="bottom"
            color="white"
            v-if="headerInfo.processName?.length > 13"
          >
            <div class="desc business-process">
              {{ headerInfo.processName }}
            </div>
          </a-tooltip>
          <div class="desc business-process" v-else>
            {{ headerInfo.processName ?? '-' }}
          </div>
        </div>
        <!-- </div> -->
        <!-- <div class="line"> -->
        <!-- 申请人 -->
        <div :class="['text-block', hasCover ? 'maximum-width' : '']">
          <span class="title">申请人：</span>
          <a-tooltip
            :title="headerInfo.staffInfoVO?.staffName"
            overlayClassName="tooltip-custom"
            placement="bottom"
            color="white"
            v-if="headerInfo.staffInfoVO?.staffName?.length > 5"
          >
            <div class="desc staffName">
              {{ headerInfo.staffInfoVO?.staffName }}
            </div>
          </a-tooltip>
          <div class="desc staffName" v-else>
            {{ headerInfo.staffInfoVO?.staffName }}
          </div>
        </div>
        <div class="text-block department-box" v-if="showSection">
          <span class="title">部门：</span>
          <a-tooltip
            :title="headerInfo.staffInfoVO?.deptName"
            overlayClassName="tooltip-custom"
            placement="bottom"
            color="white"
            v-if="headerInfo.staffInfoVO?.deptName?.length > 5"
          >
            <div class="desc">
              {{ headerInfo.staffInfoVO?.deptName ?? '-' }}
            </div>
          </a-tooltip>
          <span class="desc" v-else>
            {{ headerInfo.staffInfoVO?.deptName ?? '-' }}
          </span>
        </div>
        <!--  -->
        <div class="text-block copySend-box" v-if="showCopy && headerInfo.startCopySendList">
          <span class="title">发起抄送人：</span>
          <a-tooltip overlayClassName="tooltip-custom" placement="bottom" color="white">
            <template #title>
              <span v-for="(item, index) in headerInfo.startCopySendList" :key="item">
                <span>{{ item.dataName }}</span>
                <span v-if="index != headerInfo.startCopySendList.length - 1">、</span>
              </span>
            </template>
            <div class="desc">
              <span v-for="(item, index) in headerInfo.startCopySendList" :key="item">
                <span class="copy-name">{{ item.dataName }}</span>
                <span v-if="index != headerInfo.startCopySendList.length - 1">、</span>
              </span>
            </div>
          </a-tooltip>
        </div>
        <div class="text-block copySend-box" v-if="showCopy && headerInfo.endCopySendList">
          <span class="title">结束抄送人：</span>
          <!-- :title="headerInfo.staffInfoVO?.deptName" -->
          <a-tooltip overlayClassName="tooltip-custom" placement="bottom" color="white">
            <template #title>
              <span v-for="(item, index) in headerInfo.endCopySendList" :key="item">
                <span>{{ item.dataName }}</span>
                <span v-if="index != headerInfo.endCopySendList.length - 1">、</span>
              </span>
            </template>
            <div class="desc">
              <span v-for="(item, index) in headerInfo.endCopySendList" :key="item">
                <span class="copy-name">{{ item.dataName }}</span>
                <span v-if="index != headerInfo.endCopySendList.length - 1">、</span>
              </span>
            </div>
          </a-tooltip>
        </div>
        <!-- 申请时间/申请日期 -->
        <div :class="['text-block', hasCover ? 'maximum-width' : '']" v-if="showTime">
          <span class="title"
            >{{ headerInfo.applyTime && headerInfo.applyTime.includes(':') ? '申请时间' : '申请日期' }}：</span
          >
          <span class="desc">{{ headerInfo.applyTime }}</span>
        </div>
        <!-- 盖印方式 -->
        <div :class="['text-block', hasCover ? 'maximum-width' : '']" v-if="hasCover">
          <span class="title">盖印方式：</span>
          <span class="desc">{{ StampingMethod[headerInfo.remote]?.name }}</span>
        </div>
      </div>
    </div>
    <div :style="{ width: rightRefWidth }">
      <div ref="rightRef" :class="['right', !showSealStatus ? 'no-seal-status' : '']">
        <!-- 用印记录 -->
        <!-- 用印状态为用印中或已完成的用印申请，可点击查看用印记录 -->
        <div
          class="seal-record"
          v-if="
            ((headerInfo.sealStatus === 2 || headerInfo.sealStatus === 3 || headerInfo.sealStatus === 5) &&
              showSealRecord) ||
              showSealRequire
          "
          :style="{ 'margin-bottom': showSealStatus || showApplyStatus ? '17px' : 0 }"
        >
          <div @click="goRecord(headerInfo.sealStatus)">用印记录 <RightOutlined /></div>
        </div>
        <!-- 申请状态/用印状态 -->
        <div class="status-area" v-if="showSealStatus || showApplyStatus">
          <div class="status" v-if="showApplyStatus">
            <p class="status-title">申请状态</p>
            <div class="status-box">
              <div
                class="status-dot"
                :style="{
                  background: examineStatusObj[headerInfo.examineStatus]?.color
                }"
              ></div>
              <span class="status-span">{{ examineStatusObj[headerInfo.examineStatus]?.name }}</span>
            </div>
          </div>
          <div class="status" v-if="showSealStatus && !hasCover">
            <p class="status-title">用印状态</p>
            <div class="status-box">
              <div
                class="status-dot"
                :style="{
                  background: sealStatusObj[headerInfo.sealStatus]?.color
                }"
              ></div>
              <span class="status-span">{{ sealStatusObj[headerInfo.sealStatus]?.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { RightOutlined } from '@ant-design/icons-vue'
import { examineStatusObj, sealStatusObj } from '@/utils/constData'
import { useRouter } from 'vue-router'
import { getEnums } from '@/utils/utils'

export default defineComponent({
  components: {
    RightOutlined
  },
  props: {
    headerInfo: {
      type: Object,
      default: () => {}
    },
    showSealStatus: {
      //是否显示用印状态
      type: Boolean,
      default: () => true
    },
    showApplyStatus: {
      //是否显示申请状态
      type: Boolean,
      default: () => true
    },
    showSealRecord: {
      //是否显示用印记录
      type: Boolean,
      default: () => true
    },
    showSealRequire: {
      //是否显示用印记录的必要参数，只要他为true就显示
      type: Boolean,
      default: () => false
    },
    showTime: {
      //是否显示申请时间/申请日期
      type: Boolean,
      default: () => false
    },
    showCopy: {
      //是否显示抄送人
      type: Boolean,
      default: () => true
    },
    hasCover: {
      //是否补盖
      type: Boolean,
      default: () => false
    },
    showSection: {
      // 是否显示部门
      type: Boolean,
      default: false
    },
    isAppoval: {
      // 是否是审批
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    console.log('jjhhhhhhhhhhhh', props.headerInfo)

    const router = useRouter()
    const rightRef = ref(null)
    let rightRefWidth = ref(0)
    // 盖印方式
    const StampingMethod = {
      1: {
        name: '常规盖印'
      },
      2: {
        name: '远程盖印'
      },
      3: {
        name: '连续盖印'
      }
    }

    const sealStatusToPath = {
      2: '/seal/applyWaitList/detail', //用印中
      3: '/seal/applyCompleteList/detail', //用印完成
      4: '/seal/applyCompleteList/detail', //已结束
      5: '/seal/applyArchiveList/detail', //已归档
      6: '/seal/applyCompleteList/detail' //归档中
    }

    //跳转用印记录详情
    const goRecord = type => {
      router.push({
        path: sealStatusToPath[type],
        query: {
          documentId: props.headerInfo.documentId,
          processInstanceId: props.headerInfo.processInstanceId
        }
      })
    }
    onMounted(() => {
      rightRefWidth.value = rightRef.value.offsetWidth + 'px'
      console.log(rightRef.value.offsetWidth, 'console.log(rightRef.value)')
      // console.log('头部数据', props.headerInfo)
    })
    return {
      examineStatusObj,
      sealStatusObj,
      goRecord,
      StampingMethod,
      getEnums,
      rightRef,
      rightRefWidth
    }
  }
})
</script>

<style lang="scss" scoped>
.detail-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -48px;
  background-color: #fff;
  .left {
    flex-wrap: wrap;
    // max-width: 95%;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 80px;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // z-index: 1;
    // background-color: #fff;
    // background-color: pink;
    .line {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: -8px;
      .text-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        margin-right: 60px;
        margin-bottom: 8px;
        .title {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
        }
        .desc {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }
        .staffName {
          max-width: 5em;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .business-process {
          max-width: 13em;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        // &:nth-child(1) {
        //   margin-right: 60px;
        // }
        // &:nth-child(2) {

        // }
        &:last-child {
          margin-right: 0px;
        }
      }
      .maximum-width {
        &:nth-of-type(1) {
          width: 358px;
        }
        &:nth-of-type(2) {
          width: 336px;
        }
        &:nth-of-type(1) {
          width: 238px;
        }
      }
    }
    .margin-bottom {
      margin-bottom: 8px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .big-padding {
    padding: 16px 0 16px 25px;
  }
  .small-padding {
    padding: 16px 25px;
  }
  .right {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 24px;
    position: absolute;
    right: 0px;
    bottom: 16px;
    // top: 50%;
    // transform: translateY(-50%);
    z-index: 2;
    // background-color: skyblue;

    .seal-record {
      min-height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #0A7BFF;
      cursor: pointer;
    }
    .status-area {
      display: flex;
      flex-direction: row;
      align-items: center;

      .status {
        margin-right: 48px;
        text-align: right;
        .status-title {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
        .status-box {
          display: flex;
          flex-direction: row;
          align-items: center;
          .status-dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 8px;
          }
          .status-span {
            font-size: 20px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);

            line-height: 28px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .no-seal-status {
    // position: absolute;
    // bottom: -35px;
    // padding-bottom: 0;
    // right: 0px;
    // top: auto;
  }
}

.department-box {
  // background-color: pink;

  .desc {
    max-width: 8em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.copySend-box {
  .desc {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .copy-name {
    max-width: 84px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
<style lang="scss">
//提示
.tooltip-custom {
  .ant-tooltip-inner {
    // 这里是框框
    color: #323233;
    background-color: #fff !important;
    border-radius: 2px;
  }

  .ant-tooltip-arrow::before {
    // 这里是小三角形
    background-color: #fff !important;
  }
}
</style>
