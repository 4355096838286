// 严凯 V2.2.0
<template>
  <div ref="parent">
    <a-modal
      centered
      :width="658"
      :title="titleText"
      :visible="visible"
      :footer="null"
      @cancel="$emit('update:visible', false)"
      :getContainer="() => $refs.parent"
    >
      <a-spin :spinning="currentLoading">
        <section class="content show-scrollbar">
          <section>
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="2"
              size="small"
              class="descript"
              style="border-top: 1px solid #f0f0f0"
            >
              <!-- 流程主题 -->
              <a-descriptions-item label="流程主题">{{ current?.fileName || '-' }}</a-descriptions-item>
              <!-- 盖印方式 -->
              <!-- 1、无外带审批人（常规用印） 2、有外带审批人（远程用印） 3、连续用印 -->
              <a-descriptions-item label="盖印方式" v-if="current.remote === 1">常规盖印</a-descriptions-item>
              <a-descriptions-item label="盖印方式" v-if="current.remote === 2">远程盖印</a-descriptions-item>
              <a-descriptions-item label="盖印方式" v-if="current.remote === 3">连续盖印</a-descriptions-item>
            </a-descriptions>
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="2"
              size="small"
              class="descript"
              style="border-top: 1px solid #f0f0f0"
            >
              <!-- 申请人 -->
              <a-descriptions-item label="申请人">{{ applicantText }}</a-descriptions-item>
              <!-- 印章类型 -->
              <a-descriptions-item label="印章类型">{{ current?.sealTypeName || '-' }}</a-descriptions-item>
            </a-descriptions>
            <!-- flowType用于区分是否是补盖数据 1 非补盖 2 补盖 -->
            <div v-if="current.flowType === 2">
              <a-descriptions title="" layout="vertical" bordered :column="2" size="small" class="descript">
                <a-descriptions-item label="申请印章">{{
                  (current.sealShow && current.sealShow[0]?.sealName) || '无'
                }}</a-descriptions-item>
                <a-descriptions-item label="申请补盖次数">{{
                  (current.sealShow && current.sealShow[0]?.reApplicationNumber) || '0'
                }}</a-descriptions-item>
              </a-descriptions>
              <a-descriptions title="" layout="vertical" bordered :column="2" size="small" class="descript">
                <!-- current.sealShow[0]?.sealCoverStaffRelVOList -->
                <a-descriptions-item label="盖印人">{{
                  (current.sealShow &&
                    resetName(current.sealShow[0]?.sealCoverStaffRelVOList))
                }}</a-descriptions-item>
                <a-descriptions-item label="补盖原因">{{ fillCoverText }}</a-descriptions-item>
              </a-descriptions>
              <a-descriptions title="" layout="vertical" bordered :column="1" size="small" class="descript">
                <a-descriptions-item label="申请时间">{{ current?.sealShow[0].reTime || '无' }}</a-descriptions-item>
              </a-descriptions>
            </div>
            <!-- 不是补盖情况的内容展示 -->
            <div v-else>
              <a-descriptions
                title=""
                layout="vertical"
                bordered
                :column="2"
                size="small"
                class="descript"
                v-for="(item, index) in current?.sealShow"
                :key="item.documentId"
              >
                <!-- 申请次数 -->
                <a-descriptions-item label="申请次数">{{ item.applicationNumber }}</a-descriptions-item>
                <!-- 申请印章 -->
                <a-descriptions-item :label="`申请印章${index === 0 ? '' : index + 1}`">{{
                  item.sealName
                }}</a-descriptions-item>
              </a-descriptions>

              <a-descriptions title="" layout="vertical" bordered :column="2" size="small" class="descript">
                <!-- 盖印人 -->
                <!-- current.sealShow[0]?.sealStaffRelVOList -->
                <a-descriptions-item label="盖印人">{{
                  current.sealShow &&
                    resetName(current.sealShow[0]?.sealStaffRelVOList)
                }}</a-descriptions-item>
                <!-- 预计用印日期 -->
                <a-descriptions-item label="预计用印日期">{{ current?.sealTime || '无' }}</a-descriptions-item>
              </a-descriptions>
              <a-descriptions title="" layout="vertical" bordered :column="2" size="small" class="descript">
                <!-- 申请事由 -->
                <a-descriptions-item label="申请事由">
                  {{ current?.applyText || '无' }}
                </a-descriptions-item>
                <!-- 申请时间 -->
                <a-descriptions-item label="申请时间">{{ current?.applyTime || '无' }}</a-descriptions-item>
              </a-descriptions>
            </div>
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="1"
              size="small"
              style="margin-bottom: 18px"
              class="applyContent"
            >
              <!-- 附件 -->
              <a-descriptions-item :span="2" label="附件">
                <section v-if="fillCoverFiles.length <= 0">无</section>

                <section style="display: inline-block; vertical-align: top; width: 100%" v-else>
                  <div v-viewer="otherOptions" :images="current.imgList">
                    <div class="applyFileBox" v-for="(it, index) in fillCoverFiles" :key="it.fileId">
                      <div class="seal-imgs" style="position: relative">
                        <a v-if="fileTypeObj[it.suffix]">
                          <img :src="getImgUrl(it.suffix)" class="seal-img" />
                        </a>
                        <div class="downloadFileBox" @click="downloadFile(it.fullFile)" v-if="fileTypeObj[it.suffix]">
                          <i class="iconfont icon-xiazai icons" style="color: white; font-size: 18px"></i>
                        </div>
                        <img
                          v-else
                          :src="it.fullFile"
                          alt=""
                          @error="reImg($event, it.fullFile)"
                          @click="previewImg(it.fullFile, index, 'applyFile')"
                          class="seal-img"
                        />
                      </div>
                      <div style="width: 450px" :title="it.fileName" class="single-wrap">{{ it.fileName ?? '无' }}</div>
                    </div>
                  </div>
                </section>
              </a-descriptions-item>
            </a-descriptions>
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="2"
              size="small"
              class="descript"
              style="border-top: 1px solid #f0f0f0; margin-bottom: 18px"
              v-if="current?.takeout == 2"
            >
              <!-- 外带时间 -->
              <a-descriptions-item label="外带时间">{{
                current?.startTime + '至' + current?.finishTime || '-'
              }}</a-descriptions-item>
              <!-- 外带地点 -->
              <a-descriptions-item label="外带地点">{{ current?.address || '-' }}</a-descriptions-item>
            </a-descriptions>
            <!-- 远程确认人/远程确认影像 -->
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              size="small"
              class="descript"
              style="border-top: 1px solid #f0f0f0; margin-bottom: 18px"
              v-if="current?.remote === 2"
            >
              <a-descriptions-item label="远程确认人">{{ current?.remoteStaffName || '-' }}</a-descriptions-item>
            </a-descriptions>
            <!-- 用印影像/用印视频 -->
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="2"
              size="small"
              class="descript"
              style="margin-top: -16px; margin-bottom: 16px"
              v-if="current?.documentRemoteVice && current?.remote === 2"
            >
              <a-descriptions-item label="远程确认影像">
                <div v-viewer="printImgOptions">
                  <section class="show-img-section">
                    <div v-for="(item, index) in current.documentRemoteVice" :key="index">
                      <div class="show-img-div" v-if="item.fileType === 1">
                        <img :src="item.fullFile" alt="" :data-imageType="item.imageType" class="show-img" />
                      </div>
                    </div>
                  </section>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="用印视频">
                <VideoList :videoList="current.documentRemoteVideoList" :iconUrl="iconUrl" />
              </a-descriptions-item>
            </a-descriptions>
            <!-- 连续盖印信息-->
            <a-descriptions
              style="margin: 16px 0; border-top: 1px solid #f0f0f0"
              title=""
              layout="vertical"
              bordered
              :column="1"
              size="small"
              class="applyContent"
              v-if="current?.remote === 3"
            >
              <a-descriptions-item :span="2" label="连续盖印信息" contentStyle="padding:0">
                <section>已开启</section>
              </a-descriptions-item>
            </a-descriptions>
            <!-- TODO 暂时找不到archivedFlowShow变量是什么先不使用 有问题再改回来 -->
            <!-- <approval-time-line :approvalList="[].concat(current.sealFlowShow || [], current.archivedFlowShow || [])"
              :archivedLength="current.archivedFlowShow ? current.archivedFlowShow.length : 0"
                :examineStatus="current.examineStatus" :isPrint="2" /> -->
            <approval-time-line
              :approvalList="processList"
              :examineStatus="current.examineStatus"
              :isPrint="2"
              :isFill="current?.flowType === 2"
            />
          </section>
          <div v-if="isApproval" class="mark">
            <p>审批意见</p>
            <a-textarea
              placeholder="请输入审批意见"
              style="100%"
              @pressEnter="e => lineFeed(e)"
              v-model:value="state.mark"
              :maxlength="50"
              showCount
              :autoSize="{ minRows: 3 }"
            ></a-textarea>
          </div>
        </section>
        <div class="action-box" v-if="hasFoot">
          <a-button v-if="isApproval" class="btn close" @click="$emit('update:visible', false)">取消</a-button>
          <a-button v-if="isApproval" class="btn cancel" :loading="loadingReject" @click="comfirmApproval(4)"
            >拒绝</a-button
          >
          <a-button class="btn comfirm" @click="comfirmApproval(3)" :loading="loadingAgree">{{
            isApproval ? '同意' : '取消'
          }}</a-button>
        </div>
      </a-spin>
    </a-modal>
  </div>
  <!-- 视频放大 -->
  <div v-if="plankShow" class="plank" @click="closePlank">
    <div class="video-center" @click.stop="close">
      <videoPlayer
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="{
          autoplay: true, // 立即播放
          muted: false, // 默认情况下将会消除任何音频。
          language: 'zh-CN',
          // aspectRatio: '16:10',  显示比例
          fluid: true, // 按比例缩放以适应其容器。
          sources: [
            {
              type: 'video/mp4', // 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误
              src: shop[videoIndex].url
            }
          ],
          notSupportedMessage: '此视频暂无法播放...', // 无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: true,
            fullscreenToggle: true //全屏按钮
          }
        }"
      >
      </videoPlayer>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref, watch, onMounted } from 'vue'
import PhotoView from '@/components/PhotoView'
import '@/style/jsx-label-text.scss'
import { cmsMessage, cmsNotice } from '@/utils/utils'
import LabelText from '@/components/LabelText'
import { sealStatusObj, examineStatusObj, approvalStatusObj, archiveStatusObj, fileTypeObj } from '@/utils/constData'
import ApprovalTimeLine from '@/views/components/approvalTimeLine'
import { useRouter } from 'vue-router'
import { videoPlayer } from 'vue-video-player/src'
import 'video.js/dist/video-js.css'
//用印影像|用印视频组件
import VideoList from '@/components/VideoList'
// import fillModal from './fill-modal.vue'

const typesObj = {
  1: {
    name: '单次用印',
    color: '#C3161C'
  },
  2: {
    name: '连续用印',
    color: '#5F99FF'
  },
  3: {
    name: ' --',
    color: '#ccc'
  }
}
export default defineComponent({
  components: {
    // LabelText,
    ApprovalTimeLine,
    // PhotoView,
    videoPlayer,
    //用印影像|用印视频组件
    VideoList
  },
  emits: ['modalSubmit'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isApproval: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: () => ({
        documentFileRelVO: [],
        documentApplyFileS: [],
        sealFlowShow: [],
        examineType: 1,
        status: 1,
        sealShow: [],
        sealFlowCoverShow: []
      })
    },
    loadingReject: {
      type: Boolean,
      default: false
    },
    loadingAgree: {
      type: Boolean,
      default: false
    },
    currentLoading: {
      type: Boolean,
      default: true
    },
    hasFoot: {
      type: Boolean,
      default: true
    },
    isHome: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const router = useRouter()
    const state = reactive({
      mark: undefined
    })
    const imgList = ref([])
    const previewVisible = ref(false)
    const isApproval = computed(() => props.isApproval) // 是否审批
    // 补盖原因
    const fillCoverText = computed(() => {
      return props.current.sealShow && props.current.sealShow[0].reReason === 5
        ? props.current.sealShow[0].reText
        : props.current.sealShow[0].reReasonName
    })
    // 申请人文本
    const applicantText = computed(() => {
      return props.current.flowType === 1
        ? props.current?.staffName || '-'
        : props.current.sealShow[0]?.affixedStaffName || '-'
    })
    const isVirtualListScroll = ref(0)
    const photoIndex = ref(0)
    const videoIndex = ref(0)
    const shop = reactive([
      {
        url: 'https://ydatestapi.libawall.com/storage/27466',
        type: 'img'
      },
      {
        url: 'http://vjs.zencdn.net/v/oceans.mp4',
        type: 'video'
      },
      {
        url: 'https://ydatestapi.libawall.com/storage/28309',
        type: 'img'
      },
      {
        url: 'https://ydatestapi.libawall.com/storage/107191',
        type: 'img'
      },
      {
        url: 'https://media.vued.vanthink.cn/sparkle_your_name_am360p.mp4',
        type: 'video'
      }
    ])
    //视频播放icon
    const iconUrl = 'assets/images/playIcon.png'
    // 视频预览
    const plankShow = ref(false)
    // 处理需要展示的流程数据
    const processList = computed(() => {
      // 补盖流程数据
      if (props.current.flowType === 2) {
        // type 1 申请人 2 审批人
        let list = []
        list = props.current.sealFlowCoverShow.filter(item => {
          return item.type === 1
        })
        const children = props.current.sealFlowCoverShow.filter(item => {
          return item.type === 2
        })
        if (children.length > 0) list.push({ children })
        return list
      }
      // 非补盖流程数据
      return props.current.sealFlowShow
    })
    const fillCoverFiles = computed(() => {
      if (props.current.documentApplyFileS === null || props.current.documentApplyFileS.length <= 0) return []
      if (props.current.flowType === 2) {
        // status 1 用印申请附件 2 补盖附件
        return (
          props.current?.documentApplyFileS.filter(item => {
            return item.status === 2
          }) || []
        )
      }
      // 非补盖附件数据
      return (
        props.current?.documentApplyFileS.filter(item => {
          return item.status === 1
        }) || []
      )
    })
    const openPlank = (item, index) => {
      plankShow.value = true
      videoIndex.value = index
    }
    const closePlank = () => {
      plankShow.value = false
    }
    const comfirmApproval = status => {
      console.log('status', status)
      if (!isApproval.value) {
        emit('update:visible', false)
        return
      } else {
        emit('update:loading', true)
        state.mark = state.mark?.trim()
        if (status === 4) {
          console.log('拒绝')

          if (!state.mark) {
            cmsMessage('error', '请输入审批意见')
            return
          } else {
            emit('update:loadingReject', true)
          }
        }
        if (status === 3) {
          console.log('同意')
          emit('update:loadingAgree', true)
        }
        emit('modalSubmit', status, state.mark)
      }
    }
    const previewImg = (src, i, type) => {
      if (!src) return
      imgList.value = []
      if (type === 'applyFile') {
        props.current.documentApplyFileS?.map(item => {
          if (!fileTypeObj[item.suffix]) {
            imgList.value.push(item.fullFile)
          }
        })
      } else if (type == 'remoteFile') {
        props.current.documentRemoteVice?.map(item => {
          if (!fileTypeObj[item.suffix]) {
            imgList.value.push(item.fullFile)
          }
        })
      }
      photoIndex.value = imgList.value.indexOf(src)
      previewVisible.value = true
    }
    const reImg = (e, src, sign = 'a') => {
      const parentNode = e.target.parentNode
      const tipsSpan = document.createElement(sign)
      parentNode.style.padding = '10px'
      if (sign === 'a') {
        const tipsDiv = document.createElement('div')
        tipsDiv.id = 'error-imgs'
        tipsDiv.style.height = '104px'
        tipsDiv.style.width = '104px'
        tipsDiv.style.background = '#F2F2F2'
        tipsDiv.style.textAlign = 'center'
        // tipsDiv.style.transform = 'rotateZ(180deg)'
        tipsDiv.style.color = '#B1B1B1'
        tipsDiv.style.padding = '40px 0 0 0'
        const tipsI = document.createElement('i')
        const tipsText = document.createTextNode(src ? '加载失败' : '同步失败')
        tipsI.className = 'iconfont icon-tupianjiazaishibai1 error-img'
        tipsI.style.fontSize = '30px'
        tipsI.style.color = '#D9D9D9'
        tipsI.style.marginBottom = '10px'
        tipsI.style.height = '25px'
        tipsI.style.display = 'block'
        tipsDiv.appendChild(tipsI)
        tipsDiv.appendChild(tipsText)
        parentNode.removeChild(e.target)
        parentNode.style.border = 'none'
        parentNode.style.padding = '0'
        parentNode.appendChild(tipsDiv)
        // const tipsText = document.createTextNode('1')
        // tipsSpan.appendChild(tipsText)
        // tipsSpan.addEventListener('click', () => {
        //   window.location.href = src.replace('.com//', '.com/') + '?upload=true'

        //   setTimeout(() => {
        //     cmsNotice('success', '正在为你下载，请耐心等待')
        //   }, 200)
        // })
      } else {
        const tipsText = document.createTextNode('图片未同步成功')
        tipsSpan.appendChild(tipsText)
      }

      parentNode.removeChild(e.target)
      parentNode.appendChild(tipsSpan)
    }
    // 文本框回车禁止
    const lineFeed = e => {
      e.preventDefault()
    }
    const getImgUrl = src => {
      return require('@/assets/images/' + fileTypeObj[src])
    }
    const recordDetail = () => {
      console.log(props.current)
      router.push(`/seal/applyList/detail?id=${props.current.documentId}&docType=1`)
    }
    const downloadFile = src => {
      window.location.href = src.replace('.com//', '.com/') + '?upload=true'
      setTimeout(() => {
        cmsNotice('success', '正在为你下载，请耐心等待')
      }, 200)
    }
    watch(
      () => props.visible,
      newVisible => {
        if (newVisible) {
          state.mark = undefined
        }
      }
    )

    watch(
      () => props.current,
      () => {
        isVirtualListScroll.value++
      }
    )
    // 标题文本
    const titleText = computed(() => {
      let str = '查看'
      if (isApproval.value) {
        str = props.current.flowType === 1 ? '' : '（补盖）'
        str = props.isHome ? `${str}审批详情` : `${str}审批`
      }
      return str
    })
    //盖印人 处理
    const resetName = arr => {
      // var sealCoverStaffRelVOList = sealStaffRelVOList
      const newArr = arr ? arr.map(item => item.staffName).join('、') : '无'
      return newArr
    }
    return {
      applicantText,
      fillCoverFiles,
      fillCoverText,
      processList,
      titleText,
      iconUrl,
      videoIndex,
      plankShow,
      openPlank,
      closePlank,
      shop,
      imgList,
      lineFeed,
      downloadFile,
      getImgUrl,
      fileTypeObj,
      state,
      comfirmApproval,
      sealStatusObj,
      examineStatusObj,
      approvalStatusObj,
      typesObj,
      previewVisible,
      previewImg,
      reImg,
      isVirtualListScroll,
      archiveStatusObj,
      recordDetail,
      photoIndex,
      otherOptions: {
        navbar: false,
        title: false,
        transition: false
      },
      printImgOptions: {
        navbar: false,
        title: false,
        transition: false,
        viewed(e) {
          if (e.detail.originalImage.dataset.imagetype == 1) {
            this.viewer.rotateTo(0)
          }
        }
      },
      resetName
    }
  }
})
</script>

<style lang="scss" scoped>
.approval-img {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
  transform: translateY(5px);
  margin-right: 4px;
}

.applyContent {
  :deep(.ant-descriptions-item-content) {
    padding: 0;
  }
}

.dotted {
  width: 6px;
  height: 6px;
  background: greenyellow;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}

.time-progess {
  width: 20px;
  height: 20px;
  background: #c3161c;
  border-radius: 50%;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
}

.action-box {
  display: flex;
  justify-content: flex-end;
  padding: 10px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);

  .btn {
    height: 32px;
    background: #ffffff;
    border-radius: 2px;
    margin-right: 8px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    line-height: 20px;
  }

  .close {
    color: #999;
    border: 1px solid #dadada;

    &:hover {
      color: #999;
      border-color: #dadada;
    }
  }

  .cancel {
    color: #c3161c;
    border: 1px solid #c3161c;
  }

  .comfirm {
    background: #c3161c;
    color: #fff;
    border: 1px solid transparent;
    margin-right: 0;

    &:hover {
      border-color: transparent;
    }
  }
}

:deep(.ant-modal-title) {
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
}

:deep(.ant-modal-body) {
  padding: 0;
}

.reason {
  width: 279px;
  height: 40px;
  background: #f6f6f6;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 5px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 19.2px;
  box-sizing: border-box;
  margin-left: 21.6px;
}

:deep(.ant-timeline-item-last) {
  padding-bottom: 0;
}

.content {
  padding: 24px 24px;
  max-height: 519px;
  overflow-y: auto;

  :deep(.ant-descriptions-item-label) {
    color: rgba(0, 0, 0, 0.45);
  }

  :deep(.ant-descriptions-item-content) {
    color: rgba(0, 0, 0, 0.65);
  }

  .mark {
    > p {
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      margin-bottom: 8px;
    }

    :deep(.ant-input-textarea-show-count::after) {
      top: -25px;
    }
  }

  .descript {
    :deep(.ant-descriptions-item-label) {
      width: 50%;
    }
  }

  :deep(.ant-descriptions-view:nth-of-type(1)) {
    border-top: none;
  }
}

.applyFileBox {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;

  &:hover .downloadFileBox {
    display: block;
  }

  .seal-imgs {
    margin-bottom: 0;
  }

  .downloadFileBox {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    height: 22px;
    line-height: 22px;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
}

.seal-imgs {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 3px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 11px;
  margin-bottom: 10px;
  line-height: 0;
  cursor: pointer;

  &:nth-child(7n) {
    margin-right: 0;
  }

  .seal-img {
    width: 63px;
    height: 63px;
  }
}

// 视频
.plank {
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .video-center {
    // height: 56.25rem;
    width: 56.25rem;
    // padding: 0;
  }
}

.video-box {
  // margin-left:20px;
  position: relative;
  width: 71px;
  height: 71px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-right: 11px;
  display: flex;
  justify-content: center;
  align-items: center;

  .play-image {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 25px;
    left: 25px;
  }
}

// 视频播放消失
:deep(.vjs-big-play-button) {
  display: none;
}
</style>

<style lang="less" scoped>
:deep(.ant-descriptions-item-content),
:deep(.ant-descriptions-item-label) {
  padding: 7px 12px !important;
}

:deep(.image-list-module) {
  //图片
  .seal-imgs {
    margin-right: 16px;
    margin-bottom: 10px;
  }

  // 视频
  .video-box {
    margin-right: 16px;
    margin-bottom: 10px;
  }
}
</style>

<style lang="less" scoped>
.show-img-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .show-img-div {
    width: 74px;
    height: 74px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 6px;
    box-sizing: border-box;
    margin-right: 16px;
    margin-bottom: 8px;

    .show-img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}

:deep(.ant-descriptions-item-content) {
  vertical-align: top;
}
</style>
