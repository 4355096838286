<template>
  <!-- <div @click="toApply" style="color:#000;position: relative;top:-20px">
    发起用印
  </div> -->
  <div class="file-mode">
    <iframe width="100%" height="100%" ref="iframeRef" :src="modeUrl"> </iframe>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { getMode } from '@/apis/user'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
const router = useRouter()

const iframeRef = ref()
const modeUrl = ref('')

const messageHandle = (e) => {
  console.log('message', e)
  toApply(e)
}

onMounted(() => {
  // 获取链接
  getMode().then((res) => {
    if (res.success) {
      modeUrl.value = res.data
      console.log('modeUrl---', modeUrl.value)
    }
  })
})
</script>

<style>
.file-mode {
  position: absolute;
  top: 148px;
  left: 208px;
  right: 0;
  bottom: 0;
}
</style>
