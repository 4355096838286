<!-- 陈旭 v2.5.0   图片、视频点击，遮罩背景生成，图片放大显示，视频自动播放 -->
<template>
  <div class="image-list-module">
    <section style="display: inline-block; vertical-align: top">
      <div style="display:inline-block" v-for="(item, index) in imgList" :key="index">
        <!-- 视频 -->
        <div v-if="item.fileType === 2 && showVideo" class="video-box">
          <video @click="openPlank(item, index)" class="min-video" :src="item.fullFile"></video>
          <img class="play-image" @click="openPlank(item, index)" :src="playIconUrl.url" alt="" />
        </div>
        <!-- 图片 -->
        <div v-else-if="item.fileType === 1 && showImg">
          <div class="seal-imgs" @click="openPlank(item, index)">
            <!-- + '?uploadFile=500x500' -->
            <img :src="item.fullFile" alt="" class="seal-img" />
          </div>
        </div>
      </div>
    </section>
    <!-- 大图展示 -->
    <div v-if="plankShow" class="plank" @click="closePlank">
      <img src="@/assets/images/switchIcon/left.png" alt="" class="previous-icon" @click.stop="previous" />
      <video :src="showUrl" autoplay controls @click.stop="close" v-if="showType === 2" class="show-content"></video>
      <div @mousewheel.prevent="rollImg" class="wrap">
        <img
          :src="showUrl"
          alt=""
          @click.stop="close"
          v-if="showType === 1"
          class="show-content show-img"
          @mousedown.prevent="moveImg"
          ref="image"
        />
      </div>
      <img src="@/assets/images/switchIcon/right.png" alt="" class="next-icon" @click.stop="next" />
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, watch } from 'vue'

export default {
  components: {},
  props: {
    imgList: {
      type: Array,
      default: () => []
    },
    showVideo: {
      type: Boolean,
      default: true
    },
    showImg: {
      type: Boolean,
      default: true
    },
    iconUrl: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    //播放按钮
    const playIconUrl = reactive({
      url: ''
    })
    watch(
      () => props.iconUrl,
      val => {
        if (val) {
          playIconUrl.url = require('@/' + props.iconUrl)
        }
      },
      { immediate: true }
    )
    const plankShow = ref(false)
    //图像地址
    const showUrl = ref('')
    //图像类型
    const showType = ref(1)
    const image = ref(null)
    //显示遮罩
    const openPlank = (item, index) => {
      plankShow.value = true
      showUrl.value = item.fullFile
      showType.value = item.fileType
    }
    //关闭遮罩
    const closePlank = () => {
      plankShow.value = false
    }
    //阻止冒泡
    const close = () => {
      return
    }
    //上一张
    const previous = () => {
      if (props.showVideo && !props.showImg) {
        //仅视频
        const newArray = props.imgList.filter(item => {
          return item.fileType === 2
        })
        const currentIndex = newArray.findIndex(item => item.fullFile === showUrl.value)
        if (currentIndex === 0) {
          showUrl.value = newArray[newArray.length - 1].fullFile
        } else {
          showUrl.value = newArray[currentIndex - 1].fullFile
        }
      } else if (!props.showVideo && props.showImg) {
        //仅图片
        const newArray = props.imgList.filter(item => {
          return item.fileType === 1
        })
        const currentIndex = newArray.findIndex(item => item.fullFile === showUrl.value)
        if (currentIndex === 0) {
          showUrl.value = newArray[newArray.length - 1].fullFile
        } else {
          showUrl.value = newArray[currentIndex - 1].fullFile
        }
      } else if (props.showVideo && props.showImg) {
        //图片和视频共存
        console.log('数组长度', props.imgList)
        const currentIndex = props.imgList.findIndex(item => item.fullFile === showUrl.value)
        console.log('当前对象的索引', currentIndex)
        if (currentIndex === 0) {
          showType.value = props.imgList[props.imgList.length - 1].fileType
          showUrl.value = props.imgList[props.imgList.length - 1].fullFile
          console.log('最后一张的类型', showType.value)
        } else {
          showType.value = props.imgList[currentIndex - 1].fileType
          showUrl.value = props.imgList[currentIndex - 1].fullFile
          console.log('上一张类型', showType.value)
        }
      }
      image.value.style.transform = 'scale(1) translate(-50%, -50%)'
      image.value.style.top = '50%'
      image.value.style.left = '50%'
    }
    //下一张
    const next = () => {
      if (props.showVideo && !props.showImg) {
        //仅视频
        const newArray = props.imgList.filter(item => {
          return item.fileType === 2
        })
        const currentIndex = newArray.findIndex(item => item.fullFile === showUrl.value)
        if (currentIndex === newArray.length - 1) {
          showUrl.value = newArray[0].fullFile
        } else {
          showUrl.value = newArray[currentIndex + 1].fullFile
        }
      } else if (!props.showVideo && props.showImg) {
        //仅图片
        const newArray = props.imgList.filter(item => {
          return item.fileType === 1
        })
        const currentIndex = newArray.findIndex(item => item.fullFile === showUrl.value)
        if (currentIndex === newArray.length - 1) {
          showUrl.value = newArray[0].fullFile
        } else {
          showUrl.value = newArray[currentIndex + 1].fullFile
        }
      } else if (props.showVideo && props.showImg) {
        //图片和视频共存
        const currentIndex = props.imgList.findIndex(item => item.fullFile === showUrl.value)
        if (currentIndex === props.imgList.length - 1) {
          showType.value = props.imgList[0].fileType
          showUrl.value = props.imgList[0].fullFile
        } else {
          showType.value = props.imgList[currentIndex + 1].fileType
          showUrl.value = props.imgList[currentIndex + 1].fullFile
        }
      }
      image.value.style.transform = 'scale(1) translate(-50%, -50%)'
      image.value.style.top = '50%'
      image.value.style.left = '50%'
    }
    //滚动放大/缩小图片/拖拽
    const rollImg = e => {
      image.value.style.top = 'auto'
      image.value.style.left = 'auto'
      let transform = image.value.style.transform
      let zoom = transform.indexOf('scale') != -1 ? +transform.split('(')[1].split(')')[0] : 1
      zoom += e.wheelDelta / 1200
      if (zoom > 0.1 && zoom < 2) {
        image.value.style.transform = 'scale(' + zoom + ')'
      }
    }
    const moveImg = e => {
      let x = e.clientX
      let y = e.clientY
      let left = x - e.target.offsetLeft
      let top = y - e.target.offsetTop
      document.onmousemove = ev => {
        e.target.style.left = ev.clientX - left + 'px'
        e.target.style.top = ev.clientY - top + 'px'
      }
      document.onmouseup = () => {
        document.onmousemove = null
      }
    }

    return {
      plankShow,
      showUrl,
      showType,
      playIconUrl,
      openPlank,
      closePlank,
      close,
      previous,
      next,
      rollImg,
      moveImg,
      image
    }
  }
}
</script>

<style lang="less" scoped>
.image-list-module {
  //图片
  .seal-imgs {
    background-color: #fff;
    width: 74px;
    height: 74px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 6px;
    box-sizing: border-box;
    cursor: pointer;
    .seal-img {
      width: 100%;
      height: 100%;
    }
  }

  // 视频
  .plank {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
    .previous-icon,
    .next-icon {
      width: 45px;
      height: 45px;
      position: absolute;
      top: 50%;
      cursor: pointer;
      z-index: 999999;
    }
    .next-icon {
      right: 20px;
    }
    .previous-icon {
      left: 20px;
    }
    .show-content {
      height: 80%;
      object-fit: fill;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .wrap {
      position: relative;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .show-img {
        position: absolute;
        cursor: move;
      }
    }
  }

  .video-box {
    position: relative;
    width: 74px;
    height: 74px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #ffffff;
    padding: 6px;
    box-sizing: border-box;
    .min-video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .play-image {
      width: 30px;
      height: 30px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
}
</style>
