<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-20 14:31:08
 * @LastEditors: cyx 
 * @LastEditTime: 2024-03-01 15:39:05
 * @FilePath: \yda_web_manage\src\views\seal\applyList\detail_3.vue
 * @Description: 用印记录详情3.0
 * 
-->
<template>
  <div class="apply-record-detail">
    <!-- 头部信息-->
    <DetailHeader
      :headerInfo="resData"
      :showSealStatus="false"
      :showApplyStatus="false"
      :showSealRecord="false"
      :showSection="true"
      :showCopy="false"
    ></DetailHeader>
    <div class="content">
      <DetaiForm :formInfo="formInfo" />
      <!-- 归档信息  -->
      <FileArchive :archiveInfos="archiveInfos" />
      <!-- 审批信息 -->
      <div class="approval-info" v-if="approvalInfo.length > 0">
        <div class="model-title">审批信息</div>
        <ApprovalFlow :approvalInfo="approvalInfo" :isApprove="true"></ApprovalFlow>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import DetailHeader from '@/components/Header/detailHeader.vue'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import DetaiForm from '@/components/detailForm/index.vue'
import FileArchive from '@/components/Archive/index.vue'
import { recordDetail } from '@/apis/seal'
import { useRoute } from 'vue-router'
import { getArchiveList } from '@/apis/archive'
export default defineComponent({
  components: {
    DetailHeader,
    DetaiForm,
    ApprovalFlow,
    FileArchive
  },
  setup() {
    const route = useRoute()
    //接口源数据
    const resData = ref({})
    // 表单部分信息
    const formInfo = ref({})
    //审批信息
    const approvalInfo = ref([])
    //归档信息
    const archiveInfos = ref({})
    //获取详情
    const getDetail = () => {
      let params = {
        documentId: route.query.documentId,
        processInstanceId: route.query.processInstanceId
      }
      recordDetail(params)
        .then(res => {
          if (res.success) {
            resData.value = res.data
            archiveInfos.value = res.data.documentArchiveDetailsVO
            console.log('777777777777777', archiveInfos.value)
            approvalInfo.value = res.data.flowNodes ? res.data.flowNodes : []
            formInfo.value = {
              formData: res.data.formData,
              formItems: res.data.formItems,
              hasArchaic: res.data.hasArchaic,
              fileElseList: res.data.fileElseList,
              hasCover: res.data.hasCover,
              documentId: route.query.documentId,
              fileCheckList: res.data?.fileCheckList || null,
              qrcodeData: {
                qrcodeDiffList: res.data.qrcodeDiffList,
                qrcodeType: res.data.qrcodeType,
                qrcodePositionType: res.data.qrcodePositionType,
                qrcodeSize: res.data.qrcodeSize
              },
              status: res.data.status
            }
            // if (res.data.status == 1) {
            //   resData.value.processName = '特权用印'
            // }
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    onMounted(() => {
      getDetail()
    })
    return {
      resData,
      formInfo,
      approvalInfo,
      getDetail,
      getArchiveList,
      archiveInfos
    }
  }
})
</script>

<style lang="scss" scoped>
.apply-record-detail {
  .content {
    padding: 16px 24px 16px;

    .approval-info {
      margin-top: 20px;
      background-color: #fff;

      .model-title {
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        padding: 16px 24px;
        border-bottom: 1px #e9e9e9 solid;
      }
    }
  }
}
</style>
