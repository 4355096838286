<!-- 印章保管员 单选组织架构modal-->
<template>
  <a-modal centered class="global-range-modal" :width="400" :visible="visible" @ok="handleOk" @cancel="handleCancel">
    <section class="borderd">
      <!-- 关键字搜索 -->
      <div class="model-search">
        <a-input
          class="model-input"
          v-model:value.trim="searchVal"
          placeholder="请输入员工姓名或部门"
          @change="searchChange"
          allow-clear
        >
          <template #prefix>
            <SearchOutlined style="color: rgba(0, 0, 0, 0.25)" />
          </template>
        </a-input>
      </div>
      <!-- 组织架构层级 -->
      <div class="model-breadcrumb" v-if="breadcrumbList.length">
        <a-breadcrumb>
          <template #separator><span style="color: black">></span></template>
          <a-breadcrumb-item
            href=""
            v-for="(item, index) in breadcrumbList"
            @click="handledBreadcrumb(item, index)"
            :key="index"
          >
            <a-tooltip placement="topRight" :title="item.label" v-if="item.departmentName?.length > 8">
              {{ item.departmentName }}
            </a-tooltip>
            <div v-else>
              {{ item.departmentName }}
            </div>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <!-- 组织架构部门数据 -->
      <div class="staff-list">
        <section v-if="staffList.length">
          <div v-for="item in staffList" :key="item.id" class="model-item">
            <div class="model-checkbox" @click="toSelected(item)">
              <div class="model-checkbox-item">
                <img class="model-checkbox-img" :src="item.icon" />
                <div :class="['model-checkbox-name', 'single-wrap', selectedId === item.dataId ? 'selected-font' : '']">
                  <a-tooltip placement="topLeft" :title="item.dataName" v-if="item.dataName.length > 8">
                    {{ item.dataName }}
                  </a-tooltip>
                  <div v-else>
                    {{ item.dataName }}
                  </div>
                </div>
              </div>
              <div class="checked-icon" v-if="item.dataType === 1 && selectedId === item.dataId">
                <CheckOutlined style="color:#0A7BFF;font-size:17px" />
              </div>
            </div>
            <div v-if="item.islower" class="model-lower-level" @click.stop="tolower(item.departmentId, item, '')">
              <span class="model-title">下级</span>
              <span>
                <RightOutlined />
              </span>
            </div>
          </div>
        </section>
        <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE" style="margin-top: 150px" />
      </div>
    </section>
  </a-modal>
</template>

<script setup>
import { defineEmits, defineProps, reactive, ref, toRefs, toRaw, watch, onBeforeMount, computed } from 'vue'
import { SearchOutlined, RightOutlined, CheckOutlined } from '@ant-design/icons-vue'
import { getscope, getscopelower, getSearchData } from '@/apis/businessManage'
import { Empty } from 'ant-design-vue'
import { useStore } from 'vuex'
const store = useStore()
const breadcrumbList = ref([])
const crumbList = ref([])
const staffList = ref([])
const searchVal = ref() //关键字
const timer = ref(null) // 搜索节流
const selectedId = ref() ///当前选中的staff id
const selectedItem = ref()
const props = defineProps(['visible', 'selectData', 'custodianItem'])
const { visible } = toRefs(props)
const emits = defineEmits(['update:visible', 'custodianOk'])
const enterpriseName = computed(() => store.state.user.userInfo.result.enterpriseName)
// console.log(props.visible);
// 获取组织架构数据列表
const getInitList = async () => {
  const res = await getscope({ sealId: '' })
  if (res.success) {
    handelData(res)
  }
}
const handelData = res => {
  let department = res.data.departmentList.map(item => {
    item.islower = true
    item.dataName = item.departmentName
    item.dataType = 2
    item.dataId = item.departmentId
    item.icon = require('@/assets/images/checkStaff/level.png')
    return item
  })
  let personnel = res.data.staffList.map(item => {
    item.islower = false
    item.dataName = item.staffName
    item.dataType = 1
    item.dataId = item.staffId
    item.icon = item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')
    return item
  })
  staffList.value = department.concat(personnel)
  console.log('左侧数据', staffList)
}
getInitList()

// 下一级部门
const tolower = async (id, val, flg) => {
  const res = await getscopelower({ departmentId: id })
  handelData(res)
  crumbList.value = res.data.crumbList
  if (flg === 'jump') return
  breadcrumbList.value.push({
    departmentName: val.departmentName,
    departmentId: val.departmentId
  })
}
// 面包屑
const handledBreadcrumb = (e, i) => {
  console.log(e)
  breadcrumbList.value.splice(i + 1, breadcrumbList.value.length - i)
  if (e.departmentName === enterpriseName.value) {
    getInitList()
    return
  }
  tolower(e.departmentId, '', 'jump')
}
// 关键字搜索
const searchChange = e => {
  if (timer.value) {
    clearTimeout(timer.value)
  }
  timer.value = setTimeout(() => {
    let data = e.target.value
    console.log('搜索输入内容data', data)
    breadcrumbList.value.splice(1, breadcrumbList.value.length)
    if (!data) return getInitList()
    searchData(data)
  }, 800)
}
//搜索请求
const searchData = async data => {
  const res = await getSearchData({ search: data })
  console.log('搜索到的值res', res)
  handelData(res)
}
// 点击选择
const toSelected = data => {
  console.log(data)
  if (data.dataType === 2) return false
  selectedId.value = data.dataId
  selectedItem.value = data
}
const handleCancel = () => {
  console.log(112)
  emits('update:visible', false)
  selectedId.value = undefined
}
const handleOk = () => {
  emits('custodianOk', selectedItem.value)
}
onBeforeMount(() => {
  breadcrumbList.value = [{ departmentName: enterpriseName.value }]
})
watch(
  () => props.visible,
  async val => {
    if (val) {
      selectedId.value = props.selectData
      searchVal.value = ''
      selectedItem.value = props.custodianItem
      console.log(props.custodianItem)
      if (props?.custodianItem?.custodianDepartmentId) {
        await tolower(props.custodianItem.custodianDepartmentId, '', 'jump')
        breadcrumbList.value = [{ departmentName: enterpriseName.value }, ...crumbList.value]
      } else {
        // getInitList()
      }
      // props.custodianDepartmentId && tolower(e.id, '', 'jump')
    }
    console.log(breadcrumbList)
  }
  // { immediate: true }
)
</script>

<style lang="less" scoped>
.borderd {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 16px;
}
.model-search {
  display: flex;
  .model-button {
    margin-left: 20px;
  }
}
.model-breadcrumb {
  margin-top: 12px;
  margin-bottom: 9px;
}
.model-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .model-checkbox {
    flex: 1;
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .model-checkbox-item {
      display: flex;
      align-items: center;
      .model-checkbox-img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin-left: 2px;
        margin-right: 8px;
      }

      .model-checkbox-name {
        display: inline-block;
        max-width: 225px;
        cursor: pointer;
      }
    }
  }
}
.model-lower-level {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #0A7BFF;
  cursor: pointer;
  .model-title {
    margin-right: 12px;
  }
}
:deep(.ant-breadcrumb a) {
  color: #333333;
  font-size: 12px;
  line-height: 12px;
  max-width: 110px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  direction: rtl;
}
:deep(.ant-breadcrumb > span:last-child a) {
  color: #0A7BFF;
  font-size: 12px;
}
.selected-font {
  color: #0A7BFF;
}
.staff-list {
  overflow: scroll;
  max-height: 300px;
}
</style>
