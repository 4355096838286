<!--
 * @Author: cyx 
 * @Date: 2023-05-17 15:14:44
 * @LastEditors: cyx 
 * @LastEditTime: 2023-05-17 18:55:31
 * @FilePath: /yda_web_manage/src/components/Upload/uploadImg.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="upload">
    <a-upload :file-list="fileList" name="file" list-type="picture-card" :show-upload-list="false"
      :before-upload="beforeUpload" :action="action" @change="handleChange" :withCredentials="false">
      <img  v-if="imageUrl" :src="imageUrl" class="cover" />
      <div v-else>
        <loading-outlined v-if="loading"></loading-outlined>
        <plus-outlined v-else></plus-outlined>
        <div class="ant-upload-text">{{ title }}</div>
      </div>
    </a-upload>
  </div>
</template>
  
<script>
import { defineComponent,ref } from 'vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { notification, message } from 'ant-design-vue'

function alertInfo(description) {
  notification['error']({
    message: '提示',
    description
  })
}

export default defineComponent({
  components: {
    LoadingOutlined,
    PlusOutlined
  },
  name: '',
  props: {
    fileList:Array,
    fileType: Array,
    fileSize: Number,
    title: String,
    action: String,
    imageUrl:String
  },
  emits:['fileChange','update:fileList'],
  setup(props,{emit}) {
    const loading=ref(false)
       //文件上传前处理
     const beforeUpload=(file)=> {
      return new Promise((resolve, reject) => {
        const suffix = file.name.split('.').pop() //获取文件后缀名
        const size = Math.floor(file.size / (1024 * 1024))
        //限制文件大小
        if (props.fileSize && size > props.fileSize) {
          alertInfo(`大小不能超过${props.fileSize}M`)
          return reject(false)
        }
        //限制文件格式
        if (props.fileType && props.fileType.length !== 0 && !props.fileType.includes(suffix)) {
          alertInfo(`格式错误，仅支持${props.fileType.join(',')}格式`)
          return reject(false)
        }
        return resolve(true)
      })
      }
      const handleChange=({file, fileList})=> {
      console.log('fileList',fileList)
        emit('update:fileList',fileList)
        if (file.status === 'uploading') {
          loading.value = true
          return
        }

        if (file.status === 'done') {
            emit('fileChange', file)
          loading.value = false
        }

        if (file.status === 'error') {
          loading.value = false
          message.error('upload error')
        }
      }
    return {
      loading,
      handleChange,
      beforeUpload
    }
  },
})
</script>
<style lang="scss" scoped>
.upload {
  .cover {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  :deep(.ant-upload) {
    width: 60px;
    height: 60px;
    margin: 0;
    padding: 0;
  }
}
</style>
  