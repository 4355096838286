// 严凯 V2.2.0 /* * @Descripttion: 常规用印详情 * @version: 2.2.1 *
@LastEditors: 邓亚鑫 * @LastEditTime: 2022-03-25 14:56 * @version: 2.2.2 *
@LastEditors: 邓亚鑫 * @LastEditTime: 2022-04-02 15:10 */ // 刘志坤 V2.5.0
<template>
  <div>
    <a-spin :spinning="loading">
      <section class="seal-detail">
        <a-card class="seal-title" v-if="$route.query.fingerId">
          <a-row style="margin-bottom: 8px" justify="space-between">
            <a-col>
              <span class="label">设备编号：</span>
              <span class="value">{{ current.number ?? '无' }}</span>
            </a-col>
          </a-row>
          <a-row justify="end">
            <a-col> </a-col>
          </a-row>
        </a-card>
        <a-card class="seal-title" v-else>
          <a-row style="margin-bottom: 8px" justify="space-between">
            <!-- 流程主题 -->
            <a-col>
              <div class="info-block">
                <span class="info-title">流程主题：</span>
                <a-tooltip
                  :title="current.fileName"
                  overlayClassName="tooltip-custom"
                  placement="bottom"
                  color="white"
                  v-if="current.fileName && current.fileName?.length > 12"
                >
                  <span class="info-content content-wrap">{{
                    current.fileName ?? '无'
                  }}</span>
                </a-tooltip>
                <span class="info-content content-wrap" v-else>{{
                  current.fileName ?? '无'
                }}</span>
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-bottom: 8px" justify="space-between">
            <a-col class="head-col" :span="18">
              <a-row>
                <!-- 申请人 -->
                <div class="info-block">
                  <div class="info-title applicant">
                    <div class="special">
                      <span>申</span><span>请</span><span>人</span>
                    </div>
                    ：
                  </div>
                  <a-tooltip
                    :title="current.staffName"
                    overlayClassName="tooltip-custom"
                    placement="bottom"
                    color="white"
                    v-if="current.staffName && current.staffName?.length > 12"
                  >
                    <span class="info-content content-wrap">{{
                      current.staffName ?? '无'
                    }}</span>
                  </a-tooltip>
                  <span class="info-content content-wrap" v-else>{{
                    current.staffName ?? '无'
                  }}</span>
                </div>

                <!-- 盖印方式 -->
                <div class="info-block seal-way">
                  <span class="info-title">盖印方式：</span>
                  <span class="info-content" v-if="current.sizerType === 2"
                    >常规盖印</span
                  >
                  <span class="info-content" v-if="current.sizerType === 3"
                    >远程盖印</span
                  >
                  <span class="info-content" v-if="current.sizerType === 4"
                    >连续盖印</span
                  >
                </div>
              </a-row>
            </a-col>
          </a-row>
        </a-card>
        <section class="seal-tabs">
          <a-card
            title="盖印信息"
            class="SealInfo"
            style="margin-bottom: 16px;"
          >
            <a-card>
              <a-row
                v-for="(item, index) in current.sealShow"
                :key="item.documentId"
                sign="3"
                align="top"
                style="margin-bottom:8px;border-bottom:1px solid balck"
              >
                <a-col
                  :span="8"
                  :style="[
                    {
                      marginBottom:
                        anotherInfo[1].applyText && isShowCoverInfo
                          ? '16px'
                          : '0'
                    },
                    { display: 'flex' }
                  ]"
                >
                  <span class="label"
                    >印章名称{{ index === 0 ? '' : index + 1 }}：</span
                  >
                  <span class="value" :title="item.sealName">{{
                    item.sealName
                  }}</span>
                </a-col>
                <a-col
                  :span="8"
                  v-if="
                    anotherInfo[1].applyText &&
                      isShowCoverInfo &&
                      item.sealCoverStaffRelVOList.length > 0
                  "
                >
                  <span class="label">补盖盖印人：</span>
                  <!-- <span class="value">{{ item.reAffixedStaffname }}</span> -->
                  <section class="value value-omit">
                    <SealStaff :list="item.sealCoverStaffRelVOList" />
                  </section>
                </a-col>
                <a-col
                  :span="8"
                  style="display:flex"
                  v-if="item.sealStaffRelVOList.length > 0"
                >
                  <span class="label">盖印人：</span>
                  <!-- <span class="value" :title="item.staffName">{{ item?.affixedStaffName || '无' }}</span> -->
                  <section class="value value-omit">
                    <SealStaff :list="item.sealStaffRelVOList" />
                  </section>
                </a-col>
                <a-col :span="8" v-if="$route.query.docType == 1">
                  <span class="label">实际盖印次数：</span>
                  <span class="value">{{ item.practicalNumber }}次</span>
                </a-col>
                <!-- 实际补盖次数 -->
                <a-col
                  :span="8"
                  v-if="anotherInfo[1].applyText && isShowCoverInfo"
                >
                  <span class="label">实际补盖次数：</span>
                  <span class="value">{{ item.rePracticalNumber }}次</span>
                </a-col>
              </a-row>
            </a-card>
          </a-card>
          <a-card
            title="人脸影像"
            v-if="current.showFace && current.faces.length"
            style="margin-bottom: 16px"
          >
            <div>
              <section
                v-viewer="printImgOptions"
                :images="current.imgList"
                class="img-collection"
                style="display:flex;flex-wrap:wrap"
              >
                <div
                  class="stamped-image"
                  v-for="(item, index) in current.faces"
                  :key="item.fullFile"
                >
                  <!-- 人脸图片 -->
                  <div
                    class="seal-imgs"
                    style="margin-right:0;margin-bottom: 6px;position:relative"
                  >
                    <img
                      :src="item.fullFile + '?uploadFile=104x104'"
                      @click="previewImg(item.fullFile, index, 'printImg')"
                      alt=""
                      @error="reImg($event, item.fullFile, 'print')"
                      :data-imageType="1"
                      class="seal-img"
                    />
                    <!-- 补盖标识 -->
                    <i
                      v-if="item.imageType === 3 && isShowCoverInfo"
                      :style="{
                        display: 'block',
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        width: '37px',
                        height: '34px',
                        background:
                          item.imageType === 3
                            ? `url(${require('@/assets/images/seal-record/cover.png')})`
                            : `url(${require('@/assets/images/icon.png')})`,
                        backgroundSize: '100%'
                      }"
                    ></i>
                  </div>
                  <p>
                    {{ item.createTime ? item.createTime.slice(0, -3) : '' }}
                  </p>
                  <div style="text-align: center;margin-top:4px">
                    {{ item.address === '[]' ? '暂无位置信息' : item.address }}
                  </div>
                </div>
              </section>
            </div>
          </a-card>
          <!-- 用印影像 -->
          <a-card
            title="用印影像"
            style="margin-bottom: 16px;"
            class="images-card"
          >
            <div>
              <section
                v-viewer="printImgOptions"
                :images="current.imgList"
                class="img-collection"
                style="display:flex;flex-wrap:wrap"
                v-if="
                  current.documentFileRelVO && current.documentFileRelVO.length
                "
              >
                <div
                  class="stamped-image"
                  v-for="(item, index) in current.documentFileRelVO"
                  :key="item.fullFile"
                >
                  <!--判断是否为简易款-->
                  <div v-if="item.deviceType !== 2">
                    <!-- 影像开启 -->
                    <div v-if="!item.pictureType">
                      <!-- 影像抓拍成功 -->
                      <div v-if="item.photoType === 1">
                        <!-- 补拍图片2|用印图片1|补盖图片3-->
                        <div
                          v-if="
                            (item.imageType === 2 ||
                              item.imageType === 1 ||
                              (item.imageType === 3 && isShowCoverInfo)) &&
                              item.fullFile
                          "
                          class="seal-imgs"
                          style="margin-right:0;margin-bottom: 6px;position:relative"
                        >
                          <img
                            :src="item.fullFile + '?uploadFile=104x104'"
                            @click="
                              previewImg(item.fullFile, index, 'printImg')
                            "
                            alt=""
                            @error="reImg($event, item.fullFile, 'print')"
                            :data-imageType="item.imageType"
                            class="seal-img"
                          />
                          <!-- 补拍2/补盖3   icon -->
                          <i
                            v-if="
                              item.imageType === 2 ||
                                (item.imageType === 3 && isShowCoverInfo)
                            "
                            :style="{
                              display: 'block',
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              width: '37px',
                              height: '34px',
                              background:
                                item.imageType === 3
                                  ? `url(${require('@/assets/images/seal-record/cover.png')})`
                                  : `url(${require('@/assets/images/icon.png')})`,
                              backgroundSize: '100%'
                            }"
                          ></i>
                          <span class="seal-name">{{ item.staffName }}</span>
                        </div>
                        <!-- 同步中 -->
                        <div
                          class="video-synchronization"
                          v-if="!item.fullFile"
                        >
                          <img
                            src="@/assets/images/video-synchronization.png"
                          />
                          <!-- 补盖icon -->
                          <i
                            v-if="item.imageType === 3 && isShowCoverInfo"
                            :style="{
                              display: 'block',
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              width: '37px',
                              height: '34px',
                              background: `url(${require('@/assets/images/seal-record/cover.png')})`,
                              backgroundSize: '100%'
                            }"
                          ></i>
                          <span class="seal-name">{{ item.staffName }}</span>
                        </div>
                      </div>
                      <!-- 抓拍失败 -->
                      <a-tooltip
                        title="由于盖印速度过快，图片抓取失败"
                        overlayClassName="tooltip-custom"
                        color="white"
                        v-if="item.photoType === 2"
                      >
                        <div class="grab-failure-img">
                          <img src="@/assets/images/grab-failure.png" />
                          <!-- 补盖icon -->
                          <i
                            v-if="item.imageType === 3 && isShowCoverInfo"
                            :style="{
                              display: 'block',
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              width: '37px',
                              height: '34px',
                              background: `url(${require('@/assets/images/seal-record/cover.png')})`,
                              backgroundSize: '100%'
                            }"
                          ></i>
                          <span class="seal-name">{{ item.staffName }}</span>
                        </div>
                      </a-tooltip>
                    </div>
                    <!-- 影像关闭 -->
                    <div class="close-img" v-else>
                      <img src="@/assets/images/no-video.png" />
                      <!-- 补盖icon -->
                      <i
                        v-if="item.imageType === 3 && isShowCoverInfo"
                        :style="{
                          display: 'block',
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          width: '37px',
                          height: '34px',
                          background: `url(${require('@/assets/images/seal-record/cover.png')})`,
                          backgroundSize: '100%'
                        }"
                      ></i>
                      <span class="seal-name">{{ item.staffName }}</span>
                    </div>
                  </div>
                  <!-- 无影像 -->
                  <div v-else class="close-img">
                    <img src="@/assets/images/no-image.png" />
                    <!-- 补盖icon -->
                    <i
                      v-if="item.imageType === 3 && isShowCoverInfo"
                      :style="{
                        display: 'block',
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        width: '37px',
                        height: '34px',
                        background: `url(${require('@/assets/images/seal-record/cover.png')})`,
                        backgroundSize: '100%'
                      }"
                    ></i>
                    <span class="seal-name">{{ item.staffName }}</span>
                  </div>

                  <p>{{ item.sealTime ? item.sealTime.slice(0, -3) : '' }}</p>
                  <div style="text-align: center;margin-top:4px">
                    {{ item.address }}
                  </div>
                </div>
              </section>
              <span v-else>无</span>
            </div>
            <!-- <img :src="require('@/assets/images/no-img.png')"
                class="seal-no-img"
                v-else /> -->
          </a-card>
          <!-- 外带信息 -->
          <a-card
            title="外带信息"
            style="margin-bottom: 16px"
            v-if="current.takeout == 2"
          >
            <a-row>
              <a-col :span="24" style="margin-bottom: 8px">
                <span class="lable">外带时间：</span>
                <span class="value">{{
                  current.startTime
                    ? `${current.startTime}-${current.finishTime}`
                    : '无'
                }}</span>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <span class="lable">外带地点：</span>
                <span class="value">{{ current.address ?? '无' }}</span>
              </a-col>
            </a-row>
          </a-card>
          <!-- 远程确认 -->
          <a-card
            title="远程确认"
            style="margin-bottom: 16px"
            v-if="current.remote == 2"
          >
            <!-- <a-card title="远程确认" style="margin-bottom: 16px"> -->
            <!-- 远程确认人 -->
            <a-row>
              <a-col :span="24" style="margin-bottom: 9px">
                <span class="lable">远程确认人：</span>
                <span class="value">{{ current.remoteStaffName ?? '无' }}</span>
              </a-col>
            </a-row>
            <!-- 用印视频 -->
            <a-row
              v-if="
                current.documentRemoteVideoList &&
                  current.documentRemoteVideoList.length
              "
            >
              <a-col :span="24">
                <div class="lable" style="margin-bottom: 9px">用印视频：</div>
                <div class="show-content">
                  <!-- 视频 -->
                  <VideoList
                    :videoList="current.documentRemoteVideoList"
                    :iconUrl="iconUrl"
                  />
                </div>
              </a-col>
            </a-row>
            <!-- 远程确认影像 -->
            <a-row
              v-if="
                current.documentRemoteVice && current.documentRemoteVice.length
              "
            >
              <!-- <a-row > -->
              <a-col :span="24">
                <div class="lable" style="margin-bottom: 9px">
                  远程确认影像：
                </div>

                <!-- 远程确认影像列表 -->
                <div class="show-content">
                  <!-- 视频 -->
                  <!-- <VideoList :videoList="current.documentRemoteVice" :iconUrl="iconUrl" /> -->
                  <!-- 图片 -->
                  <div v-viewer="printImgOptions">
                    <section class="show-img-section">
                      <div
                        v-for="(item, index) in current.documentRemoteVice"
                        :key="index"
                      >
                        <div class="show-img-div" v-if="item.fileType === 1">
                          <img
                            :src="item.fullFile"
                            alt=""
                            :data-imageType="item.fileType"
                            class="show-img"
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-card>
          <!-- 连续盖印信息 -->
          <a-card
            title="连续盖印信息"
            style="margin-bottom: 16px"
            v-if="current.remote == 3"
            class="video-card"
          >
            <a-row>
              <a-col :span="24" style="margin-bottom: 9px;margin-left:10px;">
                <span class="lable">连续盖印：</span>
                <span class="value">已开启</span>
              </a-col>
            </a-row>
            <a-row
              v-if="
                current.documentFileVideoList &&
                  current.documentFileVideoList.length
              "
            >
              <a-col :span="24">
                <div class="lable" style="margin-bottom: 9px;margin-left:10px;">
                  用印视频：
                </div>
              </a-col>
              <a-col :span="24">
                <VideoList
                  :videoList="current.documentFileVideoList"
                  :iconUrl="iconUrl"
                  :showStaffName="true"
                />
              </a-col>
            </a-row>
          </a-card>
          <!-- 其他信息 -->
          <a-card title="其他信息">
            <div
              v-for="(infoItem, infoIndex) in anotherInfo"
              :key="infoIndex"
              :class="
                infoItem.type === 2 && isShowCoverInfo && infoItem.applyText
                  ? 'cover-box'
                  : ''
              "
            >
              <div
                v-if="
                  infoItem.type === 1 ||
                    (infoItem.type === 2 &&
                      isShowCoverInfo &&
                      infoItem.applyText)
                "
              >
                <!-- 申请事由 -->
                <a-row style="margin-bottom: 8px">
                  <a-col :span="24">
                    <span class="lable">{{
                      infoItem.type === 1 ? '申请事由：' : '补盖原因：'
                    }}</span>
                    <span class="value">{{
                      infoItem.applyText ? infoItem.applyText : '无'
                    }}</span>
                  </a-col>
                </a-row>
                <!-- 附件 -->
                <a-row
                  :style="{
                    marginBottom:
                      (!infoItem.documentApplyFileS ||
                        infoItem.documentApplyFileS.length === 0) &&
                      infoItem.type === 1
                        ? '8px'
                        : '0'
                  }"
                >
                  <a-col :span="24">
                    <span class="lable">{{
                      infoItem.type === 1 ? '附件：' : '补盖附件：'
                    }}</span>
                    <span
                      v-if="
                        !infoItem.documentApplyFileS ||
                          infoItem.documentApplyFileS.length === 0
                      "
                      >无</span
                    >
                    <section
                      style="display: inline-block; vertical-align: top;width:80%;"
                      v-else
                    >
                      <div v-viewer="otherOptions" :images="infoItem.imgList">
                        <div
                          class="applyFileBox"
                          v-for="(it, index) in infoItem.documentApplyFileS"
                          :key="it.fileId"
                        >
                          <div
                            class="seal-imgs"
                            :style="[
                              {
                                marginBottom:
                                  index ===
                                  infoItem.documentApplyFileS.length - 1
                                    ? '0px'
                                    : '10px'
                              },
                              { position: 'relative' }
                            ]"
                          >
                            <a v-if="fileTypeObj[it.suffix]">
                              <img
                                :src="getImgUrl(it.suffix)"
                                class="seal-img"
                              />
                            </a>
                            <div
                              class="downloadFileBox"
                              @click="downloadFile(it.fullFile)"
                              v-if="fileTypeObj[it.suffix]"
                            >
                              <i
                                class="iconfont icon-xiazai icons"
                                style="color:white;font-size:25px;"
                              ></i>
                            </div>
                            <img
                              v-else
                              :src="it.fullFile"
                              alt=""
                              @error="reImg($event, it.fullFile)"
                              @click="
                                previewImg(it.fullFile, index, 'applyFile')
                              "
                              class="seal-img"
                            />
                          </div>
                          <div
                            style="flex:1"
                            :title="it.fileName"
                            class="single-wrap"
                          >
                            {{ it.fileName ?? '无' }}
                          </div>
                        </div>
                      </div>
                    </section>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-card>
        </section>
      </section>
    </a-spin>
  </div>
  <div v-if="plankShow" class="plank" @click="closePlank">
    <div class="video-center" @click.stop="close">
      <videoPlayer
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="{
          autoplay: true, // 立即播放
          muted: false, // 默认情况下将会消除任何音频。
          language: 'zh-CN',
          // aspectRatio: '16:10',  展示比例
          fluid: true, // 按比例缩放以适应其容器。
          sources: [
            {
              type: 'video/mp4', // 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误
              src: shop[videoIndex].url
            }
          ],
          notSupportedMessage: '此视频暂无法播放...', // 无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: true,
            fullscreenToggle: true //全屏按钮
          }
        }"
      >
      </videoPlayer>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  getCurrentInstance
} from 'vue'
import { getApplyListDetail, getFingerprintDetail } from '@/apis/seal'
import '@/style/jsx-label-text.scss'
import { useRoute } from 'vue-router'
import { cmsNotice } from '@/utils/utils'
import {
  approvalStatusObj,
  archiveStatusObj,
  examineStatusObj,
  sealStatusObj,
  fileTypeObj
} from '@/utils/constData'
import { videoPlayer } from 'vue-video-player/src'
import SealStaff from '@/components/sealStaff/index'
import 'video.js/dist/video-js.css'

//用印视频
import VideoList from '@/components/VideoList'

const typesObj = {
  1: {
    name: '单次用印',
    color: '#C3161C'
  },
  2: {
    name: '连续用印',
    color: '#5F99FF'
  }
}

export default defineComponent({
  components: {
    // 'label-text': LabelText,
    // ApprovalTimeLineList,
    // PhotoView,
    videoPlayer,
    SealStaff,
    VideoList
  },
  setup() {
    const isShowCoverInfo = ref(false)
    //其他信息
    const anotherInfo = ref([])
    //用印视频
    const iconUrl = 'assets/images/playIcon2.png'
    const { proxy, ctx } = getCurrentInstance()
    const route = useRoute()
    const current = ref({
      documentFileIds: [],
      sealFlowShow: [],
      imgList: [],
      sealShow: []
    }) // 详情数据
    const loading = ref(true)
    const previewVisible = ref(false)
    const reverseImg = ref(false)
    const isVirtualListScroll = ref(0)
    const photoIndex = ref(0)
    const videoIndex = ref(0)
    const shop = reactive([
      {
        url: 'https://ydatestapi.libawall.com/storage/27466',
        type: 'img'
      },
      {
        url: 'http://vjs.zencdn.net/v/oceans.mp4',
        type: 'video'
      },
      {
        url: 'https://ydatestapi.libawall.com/storage/28309',
        type: 'img'
      },
      {
        url: 'https://ydatestapi.libawall.com/storage/107191',
        type: 'img'
      },
      {
        url: 'https://media.vued.vanthink.cn/sparkle_your_name_am360p.mp4',
        type: 'video'
      }
    ])
    const getDetail = () => {
      if (route.query.documentId || route.query.fingerId) {
        return getFingerprintDetail({
          documentId: route.query.documentId || route.query.fingerId
        })
      }
      return getApplyListDetail(route.query.id, route.query.sealId)
    }
    onMounted(async () => {
      const res = await getDetail()
      current.value = res.data

      console.log('详情数据', current.value)
      console.log(
        '当前印章的id',
        current.value.documentApplyFileS?.filter(
          (item) => item.status === 2 && item.sealId == route.query.sealId
        ).length
      )
      // 补盖审批中0，补盖已同意1，补盖已拒绝2
      console.log(
        ' res.data.sealShow[0].reExamine === 1的值',
        typeof res.data.sealShow[0].reExamine
      )
      isShowCoverInfo.value = res.data.sealShow[0].reExamine === 1
      //其他信息
      anotherInfo.value.push(
        //申请事由/附件
        {
          applyText: res.data.applyText,
          documentApplyFileS: current.value.documentApplyFileS?.filter(
            (item) => item.status === 1
          ),
          imgList: [],
          type: 1
        },
        //补盖原因/补盖附件
        {
          applyText:
            res.data.sealShow[0].reReasonName === '其他'
              ? res.data.sealShow[0].reText
              : res.data.sealShow[0].reReasonName,
          documentApplyFileS: current.value.documentApplyFileS?.filter(
            (item) => item.status === 2 && item.sealId == route.query.sealId
          ),
          imgList: [],
          type: 2
        }
      )
      console.log('其他信息', anotherInfo.value)

      current.value.useCount = route.query.fingerId
        ? 1
        : res.data.sealShow.reduce(
            (acct, item) => acct + item.practicalNumber,
            0
          )
      // current.value.sealShow[0].sealStaffRelVOList = []
      // current.value.sealShow[0].sealerList = []
      // for (let i = 0; i < 10; i++) {
      //   current.value.sealShow[0].sealStaffRelVOList.push({ staffId: i + 1, staffname: '1231231' })
      //   current.value.sealShow[0].sealerList.push({ staffid: i + 1, staffname: '绽放时哈哈' })
      // }
      console.log(
        current.value.sealShow[0].sealStaffRelVOList,
        current.value.sealShow[0].sealerList,
        '----------------'
      )

      loading.value = false
      isVirtualListScroll.value++
    })

    const plankShow = ref(false)
    const openPlank = (item, index) => {
      plankShow.value = true
      videoIndex.value = index
    }
    const closePlank = () => {
      plankShow.value = false
    }
    const close = () => {
      return
    }
    const previewImg = (src, i, type) => {
      console.log('9999999', src)
      if (!src) return

      current.value.imgList = []
      anotherInfo.value.forEach((item) => {
        item.imgList = []
      })

      reverseImg.value = false
      if (type == 'printImg') {
        console.log('用印影像预览', current.value.documentFileRelVO)
        current.value.documentFileRelVO?.map((item) => {
          if (item.fullFile) current.value.imgList.push(item.fullFile)
        })
        console.log('用印影像imageList的值', current.value.imgList)
        reverseImg.value = true
      } else if (type == 'applyFile') {
        // current.value.imgList = [].concat(current.value.documentApplyFileS)
        // current.value.documentApplyFileS?.map(item => {
        //   console.log('其他信息-附件的值', item)

        //   // current.value.imgList.push(item.fullFile)
        //   // anotherInfo.value[0].imgList.push(item.fullFile)
        // })

        // 用印申请附件
        anotherInfo.value[0].documentApplyFileS.map((item) => {
          anotherInfo.value[0].imgList.push(item.fullFile)
        })
        //补盖附件
        anotherInfo.value[1].documentApplyFileS.map((item) => {
          anotherInfo.value[1].imgList.push(item.fullFile)
        })
      } else if (type == 'remoteFile') {
        current.value.documentRemoteVice?.map((item) => {
          current.value.imgList.push(item.fullFile)
        })
      }
      photoIndex.value = current.value.imgList.indexOf(src)
      console.log('photoIndex的值', photoIndex.value)
      previewVisible.value = true
    }
    const getImgUrl = (src) => {
      return require('@/assets/images/' + fileTypeObj[src])
    }
    const reImg = (e, src, sign) => {
      const parentNode = e.target.parentNode
      const tipsSpan = document.createElement(sign)
      if (sign === 'a') {
        const tipsText = document.createTextNode('点击下载该附件查看')
        tipsSpan.appendChild(tipsText)
        tipsSpan.addEventListener('click', () => {
          window.location.href = src.replace('.com//', '.com/') + '?upload=true'

          setTimeout(() => {
            cmsNotice('success', '正在为你下载，请耐心等待')
          }, 200)
        })
        parentNode.removeChild(e.target)
        parentNode.appendChild(tipsSpan)
      } else {
        const tipsDiv = document.createElement('div')
        tipsDiv.id = 'error-imgs'
        tipsDiv.style.height = '104px'
        tipsDiv.style.width = '104px'
        tipsDiv.style.background = '#F2F2F2'
        tipsDiv.style.textAlign = 'center'
        // tipsDiv.style.transform = 'rotateZ(180deg)'
        tipsDiv.style.color = '#B1B1B1'
        tipsDiv.style.padding = '40px 0 0 0'
        const tipsI = document.createElement('i')
        const tipsText = document.createTextNode(src ? '加载失败' : '同步失败')
        tipsI.className = 'iconfont icon-tupianjiazaishibai1 error-img'
        tipsI.style.fontSize = '30px'
        tipsI.style.color = '#D9D9D9'
        tipsI.style.marginBottom = '10px'
        tipsI.style.height = '25px'
        tipsI.style.display = 'block'
        tipsDiv.appendChild(tipsI)
        tipsDiv.appendChild(tipsText)
        parentNode.removeChild(e.target)
        parentNode.style.border = 'none'
        parentNode.style.padding = '0'
        parentNode.appendChild(tipsDiv)
      }
      // parentNode.removeChild(e.target)
      // parentNode.appendChild(tipsSpan)
    }
    const downloadFile = (src) => {
      window.location.href = src.replace('.com//', '.com/') + '?upload=true'
      setTimeout(() => {
        cmsNotice('success', '正在为你下载，请耐心等待')
      }, 200)
    }

    return {
      isShowCoverInfo,
      anotherInfo,
      iconUrl,
      shop,
      videoIndex,
      plankShow,
      openPlank,
      closePlank,
      close,
      downloadFile,
      getImgUrl,
      fileTypeObj,
      current,
      loading,
      approvalStatusObj,
      examineStatusObj,
      sealStatusObj,
      archiveStatusObj,
      typesObj,
      previewVisible,
      reverseImg,
      previewImg,
      reImg,
      isVirtualListScroll,
      activeKey: ref('1'),
      photoIndex,
      printImgOptions: {
        navbar: false,
        title: false,
        transition: false,

        filter(image) {
          return (
            image.dataset.imagetype == 1 ||
            image.dataset.imagetype == 2 ||
            image.dataset.imagetype == 3
          )
        },
        viewed(e) {
          if (e.detail.originalImage.dataset.imagetype == 1) {
            this.viewer.rotateTo(0)
          }
        }
      },
      otherOptions: {
        navbar: false,
        title: false,
        transition: false
      }
    }
  }
})
</script>

<style lang="scss" scoped>
// :deep(.ant-spin-container) {
//   margin-top: -24px;
// }
.seal-detail {
  margin-top: -36px;

  .label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }

  .value {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    display: inline-block;
    flex: 0.9;
  }

  // .value-omit {
  //   width: 70%;
  //   word-wrap: break-word;
  //   word-break: break-all;
  //   white-space: pre-wrap;
  //   // white-space: break-spaces;
  // }

  .seal-title {
    border: none;

    :deep(.ant-card-body) {
      padding: 4px 24px;
    }

    .seal-status {
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;

      .dotted {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
        transform: translateY(-2px);
      }
    }
  }
}

// .SealInfo {
//   /deep/ >.ant-card-body{
//     padding: 0;
//   }
// }
:deep(.SealInfo > .ant-card-body) {
  padding: 0;
}

.seal-tabs {
  margin: 24px 24px 0;

  .stamped-image {
    width: 126px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 26px;
    margin-bottom: 10px;
    // margin-left: 20px;

    .video-synchronization,
    .grab-failure-img,
    .close-img {
      width: 104px;
      height: 104px;
      margin-bottom: 6px;
      position: relative;

      img {
        width: 104px;
        height: 104px;
      }
    }
  }

  .applyFileBox {
    display: flex;
    align-items: center;

    &:hover .downloadFileBox {
      display: block;
    }

    .downloadFileBox {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      height: 35px;
      line-height: 35px;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .seal-imgs {
    // border: 1px solid rgba(0, 0, 0, 0.15);
    // padding: 8px;
    border-radius: 2px;
    display: inline-block;
    margin-right: 16px;
    line-height: 0;
    cursor: pointer;
    margin-bottom: 10px;

    div {
      width: 104px;
      height: 104px;
      background-color: #e9e9e9;
    }

    &:nth-last-of-type(1) {
      margin-right: 0;
    }

    .seal-img {
      width: 108px;
      height: 108px;
      // margin-left: 20px;
    }
  }

  .seal-no-img {
    width: 104px;
    height: 104px;
  }
}

#error-imgs {
  width: 104px;
  height: 104px;
  background-color: #e9e9e9;
  text-align: center;
  font-size: 12px;
  color: #999999;

  .error-img {
    font-size: 40px;
    margin-bottom: 6px;
    display: block;
  }
}

// 视频
.plank {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .video-center {
    // height: 43.75rem;
    width: 56.25rem;
    padding: 0;
  }
}

.min-video {
  width: 104px;
  height: 104px !important;
}

.video-box {
  margin-left: 20px;
  position: relative;

  .play-image {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 37px;
    left: 37px;
  }
}

// 视频播放消失
:deep(.vjs-big-play-button) {
  display: none;
}

.img-collection {
  .seal-name {
    // display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 26px;
    background: rgba(26, 26, 27, 0.6);
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    padding: 2px 10px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.images-card {
  :deep(.ant-card-body) {
    padding-left: 16px;
  }
}

.video-card {
  :deep(.ant-card-body) {
    padding-left: 13px;
  }
}
</style>

<style lang="less" scoped>
.show-content {
  .show-img-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .show-img-div {
      width: 104px;
      height: 104px;
      margin-right: 16px;
      margin-bottom: 8px;

      .show-img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}

:deep(div.image-list-module) {
  //视频

  .video-item {
    margin-right: 26px;
    margin-bottom: 10px;
  }

  .video-box {
    position: relative;
    width: 104px;
    height: 104px;
    border: none;
    padding: 0;
    // margin-right: 16px;
    margin: auto;
    margin-bottom: 8px;

    .min-video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .play-image {
      width: 19px;
      height: 24px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
}

// .head-col {
// position: relative;
// line-height: 15px;
// display: flex;
// .applicant {
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   display: inline-block;
//   color: rgba(0, 0, 0, 0.65);
//   font-size: 14px;
//   width: 12.5rem;
// }
// .sealing-way {
//   display: inline-block;
//   position: absolute;
//   top: 0;
//   left: 19.125rem;
// }
// }
</style>

<style lang="less">
.tooltip-custom {
  .ant-tooltip-inner {
    // 这里是框框
    color: #323233;
    background-color: #fff !important;
    border-radius: 2px;
  }

  .ant-tooltip-arrow::before {
    // 这里是小三角形
    background-color: #fff !important;
  }
}
</style>

<style lang="less" scoped>
.info-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 27px;

  .info-title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }

  .applicant {
    display: flex;
    flex-direction: row;
    align-items: center;

    .special {
      width: 4em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .info-content {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }

  .content-wrap {
    width: 13em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}
</style>
<style lang="less">
//提示
.tooltip-custom {
  .ant-tooltip-inner {
    // 这里是框框
    color: #323233;
    background-color: #fff !important;
    border-radius: 2px;
  }

  .ant-tooltip-arrow::before {
    // 这里是小三角形
    background-color: #fff !important;
  }
}
</style>
<style lang="less" scoped>
.cover-box {
  padding: 16px;
  background: #f5f6f7;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
}
</style>
