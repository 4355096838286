<template>
  <div class="ocr-diff" @click="preview">
    <div class="diff-item" v-for="(item, index) in ocrFileVoList" :key="item.id">
      <div class="img-box" :data-index="index">
        <img class="img" :src="item.fullFile" :data-index="index" />
        <span :data-index="index" :class="['color-rect', item.meetType == 1 ? 'success' : 'erorr']">
          匹配率{{ item.chance }}%
        </span>
      </div>
      <!-- 文字部分 -->
      <div class="info">
        <p class="time">{{ item.time }}</p>
        <p>{{ item.address }}</p>
      </div>
    </div>
    <OCRDiffModal v-model:visible="state.visible" :ocrFileVoList="ocrFileVoList" ref="ocrDiff" />
  </div>
</template>
<script setup>
import { defineProps, reactive, ref } from 'vue'
import OCRDiffModal from '@/components/OCRDiffModal/index.vue'
const props = defineProps({
  ocrFileVoList: {
    type: Array,
    default: []
  }
})

const state = reactive({
  visible: false
})

const ocrDiff = ref(null)

const preview = e => {
  if (e.target.dataset.index) {
    const index = +e.target.dataset.index + 1
    ocrDiff.value.pageInit(index)
    state.visible = true
  }
}
</script>
<style lang="less" scoped>
.ocr-diff {
  display: flex;
  flex-wrap: wrap;
  .diff-item {
    margin-right: 32px;
    width: 160px;
    margin-bottom: 10px;

    .img-box {
      width: 160px;
      height: 104px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      .img {
        width: 100%;
      }
      .color-rect {
        position: absolute;
        top: 0;
        left: 0;
        padding: 3px 8px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        border-radius: 0px 8px 8px 8px;
      }
      .success {
        background: #00b66e;
      }
      .erorr {
        background: #9f9f9f;
      }
    }
    .info {
      .time {
        margin-top: 6px;
        margin-bottom: 4px;
        text-align: center;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
</style>
