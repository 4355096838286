function stringify (obj) {
  let arr = []
  for(let key in obj) {
    arr.push(`${key}=${obj[key] ?? ''}`)
  }
  return arr.length > 1 ? arr.join('&') : (arr[0] ?? '')
}

function parse (str) {
  let arr = str.split('&')
  let obj = {}
  arr.forEach(item => {
    if (!item) return
    const kv = item.split('=')
    obj[kv[0]] = kv[1]
  })
  return obj
}

const qs = {
  stringify,
  parse
}

export default qs