<!-- 陈旭 v2.5.0   视频点击，放大播放 -->
<!-- 刘志坤 v2.5.0  -->
<template>
  <div class="image-list-module">
    <section style="display: flex; flex-direction: row;flex-wrap: wrap;">
      <div class="video-item" :style="index!=videoList.length?'margin:0 16px 10px 0':''" style="display: inline-block" v-for="(item, index) in videoList" :key="index">
        <!-- 视频 -->
        <div class="video-box" v-if="item.fileType === 2">
          <video @click="openPlank(item, index)" class="min-video" :src="item.fullFile"></video>
          <img class="play-image" @click="openPlank(item, index)" :src="playIconUrl.url" alt="" />
          <div class="name-box" v-if="item.fileSource">
            <!-- 展示拍摄的设备 -->
            <Tooltip v-if="item.fileSource" :value="sourceType[item.fileSource - 1]" />
          </div>
        </div>
        <span class="box-video-data" v-if="showStaffName">
          <p class="sealer-name">{{ item.staffName }}</p>
          <p class="sealer-time" v-if="item.sealTime">{{ item.sealTime }}</p>
          <p class="sealer-addr" v-if="item.address">{{ item.address }}</p>
        </span>
      </div>
    </section>
    <!-- 大图展示 -->
    <div v-if="plankShow" class="plank" @click="closePlank">
      <div class="close-model">
        <img class="close-img" src="../../assets/images/closeModel.png" alt="" />
      </div>
      <video :src="showUrl" autoplay controls @click.stop="close" class="show-content"></video>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch } from 'vue'
import Tooltip from '../detailForm/components/tooltip.vue'

export default {
  components: {
    Tooltip
  },
  props: {
    videoList: {
      type: Array,
      default: () => []
    },
    iconUrl: {
      type: String,
      default: ''
    },
    showStaffName: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    //播放按钮
    const playIconUrl = reactive({
      url: ''
    })

    //来源
    const sourceType = reactive(['天玺', '金盾', '手机', '印控仪', 'APP','小程序','H5'])

    watch(
      () => props.iconUrl,
      val => {
        if (val) {
          playIconUrl.url = require('@/' + props.iconUrl)
        }
      },
      { immediate: true }
    )

    //视频地址
    const showUrl = ref('')
    const plankShow = ref(false)
    //显示遮罩
    const openPlank = (item, index) => {
      plankShow.value = true
      showUrl.value = item.fullFile
    }
    //关闭遮罩
    const closePlank = () => {
      plankShow.value = false
    }
    //阻止冒泡
    const close = () => {
      return
    }

    return {
      plankShow,
      playIconUrl,
      showUrl,
      openPlank,
      closePlank,
      close,
      sourceType
    }
  }
}
</script>

<style lang="less" scoped>
.image-list-module {
  // 视频
  .plank {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
    .close-model {
      position: absolute;
      top: 0;
      right: 0;
      .close-img {
        width: 40px;
        height: 40px;
      }
    }
    .show-content {
      height: 80%;
      width: 25%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .video-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 136px;
  }

  .video-box {
    position: relative;
    width: 74px;
    height: 74px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #ffffff;
    padding: 6px;
    box-sizing: border-box;
    .min-video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .play-image {
      width: 30px;
      height: 30px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }

    .name-box {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 26px;
      width: 100%;
      line-height: 26px;
      background: rgba(26, 26, 27, 0.6);
      :deep(.tooltip-box) {
        color: white;
        border: none;
        padding: 0;
        margin: 0 10px;
      }
    }
  }

  .box-video-data {
    width: 126px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
    text-align: center;
    font-style: normal;
    margin-top: -4px;

    .sealer-name {
      color: #333333;
      width: 84px;
      white-space: nowrap; // 不换行
      overflow: hidden; // 溢出隐藏
      text-overflow: ellipsis; // 超出显示省略号
      margin: 0 auto;
    }

    .sealer-time {
      font-family: HelveticaNeue;
      width: 100%;
    }

    .sealer-addr {
      line-height: 20px;
    }
  }
}
</style>
