export const formatNode = (data) => {
    console.log(data);
    const objData = {}
    const {
        props,
        children
    } = data
    /* 发起人 信息 
     assignedUser：【】  空数组 所有人
     type:user, 个人用户
         dept  部门 
    */
    if (props.assignedUser.length) {
        /*
         type 1员工，3部门，2角色
        */
        objData.flowPermission = props.assignedUser.map(item => ({
            type: item.type === 'user' ? 1 : 3,
            targetId: item.id,
            name: item.name
        }))
    } else {
        objData.flowPermission = []
    }
    console.log('xxxx-', objData);

}